import React from "react";
import ReactDOM from "react-dom";

import { Config } from "./config";

import { KeyCodes } from "./base/KeyCodes";
import { Point } from "./base/Point";
import { StatusCodes } from "./base/StatusCodes";

import { Cork } from "./controls/containers/Cork";

import { Dialog } from "./controls/chrome/Dialog";
import { BottomToolbar } from "./controls/chrome/BottomToolbar";
import { ZoomControl } from "./controls/chrome/ZoomControl";

import { AccountClosedModal } from "./controls/modals/AccountClosedModal";
import { AccountExceedsModal } from "./controls/modals/AccountExceedsModal";
import { AccountPastDueModal } from "./controls/modals/AccountPastDueModal";
import { InvalidEmbedModal } from "./controls/modals/InvalidEmbedModal";
import { LoginCorkModal } from "./controls/modals/LoginCorkModal";
import { LoginSubscriberModal } from "./controls/modals/LoginSubscriberModal";
import { MustHaveAccountModal } from "./controls/modals/MustHaveAccountModal";
import { TooManyUsersModal } from "./controls/modals/TooManyUsersModal";

import { ReadOnlyDatastore } from "./datastores/ReadOnly";
import { WebDatastore } from "./datastores/Web";

import { AccessControl } from "./helpers/AccessControl";
import { FocusManager } from "./helpers/FocusManager";
import { NoticeManager } from "./helpers/NoticeManager";

import { Board } from "./models/Board";
import { Item } from "./models/Item";
import { Photo } from "./models/Photo";
import { PostIt } from "./models/PostIt";
import { User } from "./models/User";

import { Device } from "./utils/Device";
import { RTC } from "./utils/RTC";
import { detectAction } from "./utils/Util";

import { UI } from "./views/UI";

class Application extends React.Component {
  config = null;
  viewport = null;
  veil = null;
  datastore = null;
  cork = null;
  notice = null;
  buttons = null;
  rtc = null;
  readOnlyDialog = null;
  toolbar = null;

  // Reference to the toolbar for the mobile edit mode.
  editModeToolbar = null;
  editModePopup = null;
  newNoteColor = "yellow";

  constructor(props, context) {
    super(props, context);

    Config.addFromQueryString();
    Config.add("application", this);

    // Create a new user with all the defaults.
    // The app will use this User instance and listen for changes;
    // this user's information is updated once login information is
    // received.
    const user = User.create();
    Config.set("user", user);
    if (Device.isMobile()) {
      // Set the title to something good for the homescreen.
      document.title = Config.get("product_name");
    }

    this.viewport = $(Config.get("viewport"));
    // Prevent the default zoom/drag on iOS devices.
    this.viewport.on("touchmove", event => event.preventDefault());
    this.viewport.css("opacity", "0");

    this.veil = $(".veil");
    this.veil.css("opacity", "1");

    this.initializeDatastore();
    this.load();
  }

  initializeDatastore() {
    const dataStoreHandlers = {
      // Respond to permission errors from the datastore.
      permissionErrorHandler: (message) => this.showReadOnlyMessage(message),
      // Broadcast RTC updates after saving items.
      afterSaveHandler: (item) => {
        if (this.rtc) {
          this.rtc.broadcastUpdate(item)
        }
      },
      // Broadcast RTC updates after deleting items.
      afterDeleteHandler: (item) => {
        if (this.rtc) {
          this.rtc.broadcastDelete(item)
        }
      }
    };

    if (Config.isViewOnlyURL()) {
      this.datastore = new ReadOnlyDatastore(dataStoreHandlers);
    } else {
      this.datastore = new WebDatastore(dataStoreHandlers);
    }

    Config.set("datastore", this.datastore);

    // Show a message if requests are currently processing
    // when the user wants to navigate away.
    $(window).on("beforeunload", () => {
      if (this.datastore.isProcessingRequests()) {
        return "We're still saving your data. Are you sure you want to continue? We should be done in a few seconds.";
      }
    });
  }

  load() {
    $(".initialization-icon").css("visibility", "visible");

    // Be very annoying if a user logs in and their account is past due.
    // This only applies to a user logging to a board already loaded;
    // this same modal is shown in afterLoad() within it's modal logic.
    AccessControl.subscribe("loggedin", user => {
      if (!Config.isLoaded()) {
        return;
      }

      if (Config.get("pastDue")) {
        new AccountPastDueModal().show();
      } else if (Config.get("planExceeded")) {
        new AccountExceedsModal().show();
      }
    });

    // Function to be called after a login when login is required.
    // data is a json object representing data sent back from the server.
    const afterLogin = (showLoading = true) => {
      // If the request was /login?redirect_to=..., then 'require_login'
      // was set to true. Let's set it false now since login was a success.
      // See if statement below.
      Config.set("require_login", false);

      // BIG DIRTY HACK! If the identifier starts with a "/",
      // that means we want to redirect ot a page. This shouldn't
      // be this way -- it's overusing the variable.
      if (Config.getIdentifier()[0] === "/") {
        window.location.replace(Config.getIdentifier());
        return;
      }

      // If no board was passed to /login as ?redirect_to=..., then
      // let's choose the first one in this user's list.
      if (Config.getIdentifier() === "") {
        Config.set("string_identifier", Config.get("user").boards[0]);
      }

      // Here to maintain the initial loading screen in the rare
      // case that the user is already logged in and no ?redirect_to
      // was specified. Loading should look normal in this case.
      if (showLoading) {
        this.showLoading();
      }

      this.load();
    };

    const failure = jqXHR => {
      let data;
      try {
        data = JSON.parse(jqXHR.responseText);
      } catch (e) {
        data = {};
      }

      this.removeTheVeil();

      const genericMessage = "Load failure! There was a problem loading your board. This is generally due to maintenance downtime or network outages. If you continue to have trouble, please contact support@noteapp.com.";

      if (jqXHR.status === StatusCodes.UNAUTHORIZED) {
        if (data["closed"]) {
          return new AccountClosedModal().show();
        } else if (data["expired"]) {
          return new MustHaveAccountModal().show();
        } else if (data["access_type"] === "shared") {
          return this.showSubscriberLogin(
            afterLogin,
            "Login to access this board.",
            false
          );
        } else if (data["access_type"] === "private") {
          return this.showSubscriberLogin(
            afterLogin,
            "Board is private. Please login.",
            false
          );
        } else if (data["access_type"] === "password-protected") {
          return this.showCorkLogin(
            afterLogin,
            "This board is password protected."
          );
        } else {
          return alert(genericMessage);
        }
      } else if (
        jqXHR.status >= StatusCodes.BAD_REQUEST &&
        jqXHR.status < StatusCodes.INTERNAL_SERVER_ERROR
      ) {
        return this.showCannotFindCorkError();
      } else {
        return alert(genericMessage);
      }
    };

    // Are we forcing the login screen to be shown? Note that this is
    // a slight hack to to SSL issues, but allows the board to seemlessly
    // load after login without another new page load. Smooth operator.
    if (Config.get("require_login")) {
      // If the user's already logged in, then proceed. Otherwise show login screen.
      if (AccessControl.isLoggedIn()) {
        afterLogin(false);
      } else {
        this.removeTheVeil();
        this.showSubscriberLogin(
          afterLogin,
          "Enter your credentials below.",
          false
        );
      }
    } else {
      // Not forcing a login. Will only be shown if backend returns a 401.

      // The datastore always knows enough info to load the cork data.
      // We only need to pass in the success and failure calls.
      this.datastore.load({
        success: data => this.afterLoad(data),
        failure
      });
    }
  }

  afterLoad(data) {
    const userData = data.user;
    delete data["user"];

    const board = Board.create(data);
    Config.add("board", board);

    // Update the user model object individually.
    Config.get("user").set(userData);

    // Make permissions publicly accessible.
    AccessControl.setPermissions(data["permissions"]);

    // Initialize the cork.
    this.cork = new Cork(
      this.viewport,
      new Point(Config.get("x"), Config.get("y")),
      Config.get("scale") / 100,
      Config.get("backgroundClass")
    );

    // Pass resize events down to the cork in case the viewport
    // is the body -- document.body does not support the resize event.
    $(window).on("resize", () => $(this.cork).trigger("resize"));

    if (Config.isOffendingEmbed() || Config.isOffendingViewOnly()) {
      this.removeTheVeil();
      const modal = new InvalidEmbedModal();
      modal.show();
      return;
    }

    // Set up RTC, but don't connect yet.
    this.rtc = new RTC(Config.get("board").api_key);

    // Receive messages.
    this.rtc.subscribe("update", ({ user, data }) => {
      let item = Item.find(data.item.id);
      const action = detectAction(item, data.item);

      // Do we have this item? Update it. If not add it.
      if (item != null) {
        item.update(data.item);
      } else {
        // Add this item.
        item = Item.create(data.item);
      }

      let messageSuffix = "";
      if (item.object instanceof PostIt && action === "edit") {
        messageSuffix = "is editing...";
      } else {
        switch (action) {
          case "move":
          case "resize":
            messageSuffix = action + "d this.";
            break;
          case "add":
          case "edit":
            messageSuffix = action + "ed this.";
            break;
          default:
            messageSuffix = action + "ed this.";
        }
      }

      return item.view.setInfoMessage(user, messageSuffix);
    });

    this.rtc.subscribe("delete", ({ user, data }) => {
      const item = Item.find(data.itemId);
      return item != null ? item.remove() : undefined;
    });

    const handleMaxUserCounts = ({ user, data }) => {
      if (!AccessControl.isAllowedIn()) {
        const board = Config.get("board");
        return new TooManyUsersModal().show(board.account.id);
      } else {
        return NoticeManager.hideModal();
      }
    };

    this.rtc.subscribe("marco", handleMaxUserCounts);
    this.rtc.subscribe("polo", handleMaxUserCounts);
    this.rtc.subscribe("subscribe", handleMaxUserCounts);
    this.rtc.subscribe("unsubscribe", handleMaxUserCounts);

    this.rtc.connect();

    Item.on("remove", (event, item) => FocusManager.blur(item));

    if (Config.get("chrome")) {
      if (!Device.isMobile()) {
        this.attachBottomToolbar();
        this.attachZoomControl();
      }
      this.addDocumentKeyBindings();
    }
    this.addEditModeHandlers();
    this.centerOnItemIfIdPresent();

    // Set the user as logged in.
    // TODO: We should normalize this to remove non-login related items.
    if (AccessControl.isAccountMember()) {
      AccessControl.setLoggedIn();
    }

    // Deselect the currently focussed item if we start dragging.
    $(this.cork).on("drag", function (event) {
      if (FocusManager.getFocus() != null) {
        return FocusManager.blur();
      }
    });

    const app = this;

    const addPostItFromEvent = function (event) {
      const obj = new PostIt({ color: app.newNoteColor });
      const item = app.cork.addItemFromEvent(obj, event);
      return item;
    };

    const addPhotoFromEvent = function (event) {
      const obj = new Photo();
      const item = app.cork.addItemFromEvent(obj, event);
      return item;
    };

    // Clean on the board to create a new note.
    $(this.cork).on("click", function (event, originalEvent) {
      if (!AccessControl.postAndEditAllowed()) {
        app.showReadOnlyMessage();
        return;
      }

      // Defocus the current item if the user has clicked the board.
      FocusManager.blur();
      const item = addPostItFromEvent(originalEvent);
      return FocusManager.focus(item);
    });

    // Bubble files dropped on the cork down
    $(this.cork).on("uploaddrop", function (event, data, dropEvent) {
      // Blur any item that's already focused -- we'll create a new one.
      let item;
      FocusManager.blur();
      let foundImage = false;
      let foundFile = false;
      const imageRegexp = /image\/(gif|png|bmp|jpe?g)/;
      let fileType = data.files[0].type;
      let index = 0;

      while (index < data.files.length) {
        const file = data.files[index];
        fileType = file.type;
        if (fileType.match(imageRegexp) == null) {
          foundFile = true;
        } else {
          foundImage = true;
        }
        index++;
      }
      const mixture = foundImage && foundFile;
      let itemToFocus = null;
      if (mixture || foundFile) {
        // If we found a mixture of items dropped, just add one note
        // with all of them as attachments.
        item = addPostItFromEvent(dropEvent);

        // Set the item associated with this file so other events can
        // have access to it down the event chain.
        index = 0;

        while (index < data.files.length) {
          data.files[index].item = item;
          index++;
        }
        itemToFocus = item;
      } else if (foundImage) {
        // They're all images. Let's add all of them to the
        index = 0;

        while (index < data.files.length) {
          // Create a new JQuery event, copying everything about the
          // drop event.
          const newEvent = $.Event(dropEvent, dropEvent);

          // Edit the location to make the images "spread" when dropped.
          newEvent.pageX = dropEvent.pageX + index * 40;
          newEvent.pageY = dropEvent.pageY + index * 40;
          item = addPhotoFromEvent(newEvent);

          // Set the item associated with this file so other events can
          // have access to it down the event chain.
          data.files[index].item = item;

          // Will ensure we always focus the last one. Cheap. Lazy.
          itemToFocus = item;
          index++;
        }
      }
      return FocusManager.focus(itemToFocus);
    });

    $(this.cork).on("uploadstarted", function (event, attachment, data) {
      const { item } = data.files[0];
      return $(item != null ? item.view : undefined).trigger("uploadstarted", [
        attachment,
        data
      ]);
    });

    $(this.cork).on("uploadprogress", function (
      event,
      attachment,
      progress,
      data
    ) {
      const { item } = data.files[0];
      return $(item != null ? item.view : undefined).trigger("uploadprogress", [
        attachment,
        progress,
        data
      ]);
    });

    $(this.cork).on("uploaddone", function (event, attachment, data) {
      const { item } = data.files[0];
      return $(item != null ? item.view : undefined).trigger("uploaddone", [
        attachment,
        data
      ]);
    });

    if (!Config.get("chrome")) {
      app.removeTheVeil(false);
      app.showViewport();
      NoticeManager.hideModal(false);
    } else {
      if (Config.get("pastDue")) {
        new AccountPastDueModal().show();
      } else if (Config.get("planExceeded")) {
        new AccountExceedsModal().show();
      } else {
        NoticeManager.hideModal(true);
      }

      app.removeTheVeil();

      // If this is a board without an account, urge them to upgrade.
      if (Config.get("board").account.id === -1) {
        app.showViewport();
        new MustHaveAccountModal().show();
      } else if (!AccessControl.isAllowedIn()) {
        // Ensure removing the veil doesn't hide the TooManyUsersModal
        // based on a race condition with RTC.

        app.showViewport();
        handleMaxUserCounts();
      } else {
        app.fadeInViewport();
      }
    }

    // Set the loaded flag to signify we've finally loaded.
    Config.set("loaded", true);
  }

  attachBottomToolbar() {
    // Change buttons based on logged in and out events.
    AccessControl.subscribe("loggedin", () => {
      if (Device.isMobile()) {
        return;
      }

      // Only explicitly show these if the board owner just logged in.
      // Otherwise permissions are handled on initialization.
      if (AccessControl.isAccountAdmin()) {
        renderToolbar();
      }
    });

    const renderToolbar = () => {
      if (this.toolbar) {
        this.toolbar.remove();
      }

      this.toolbar = document.createElement("div");
      this.toolbar.id = "toolbar";
      this.viewport.append($(this.toolbar));

      ReactDOM.render(
        <BottomToolbar
          rtc={this.rtc}
          cork={this.cork}
          onColorChange={color => this.newNoteColor = color}
          onLogin={this.showSubscriberLogin.bind(this)}
          onLogout={this.showSubscriberLogout.bind(this)}
          // If all other buttons are closed, that means something is planning
          // to open. Based on code that was in the original function before
          // a refactoring, let's ensure the readonly dialog is closed too.
          onPopupOpen={() => this.readOnlyDialog && this.readOnlyDialog.remove()}
        />,
        this.toolbar
      );
    };

    renderToolbar();
  }

  addDocumentKeyBindings() {
    const app = this;
    const buttonMapping = {};

    // Board translations.
    buttonMapping[KeyCodes.LEFT] = {
      fn(event, doc) {
        return app.moveBoard(1, 0);
      },

      altKey: true
    };

    buttonMapping[KeyCodes.RIGHT] = {
      fn(event, doc) {
        return app.moveBoard(-1, 0);
      },

      altKey: true
    };

    buttonMapping[KeyCodes.UP] = {
      fn(event, doc) {
        return app.moveBoard(0, 1);
      },

      altKey: true
    };

    buttonMapping[KeyCodes.DOWN] = {
      fn(event, doc) {
        return app.moveBoard(0, -1);
      },

      altKey: true
    };

    // Back to center.
    buttonMapping[KeyCodes.ESCAPE] = {
      altKey(event, doc) {
        app.recenterBoard();
        return app.cork.setScale(1);
      },

      ctrlKey(event, doc) {
        return this.altKey(event, doc);
      },

      metaKey(event, doc) {
        return this.altKey(event, doc);
      }
    };

    UI.addKeyBindings(document, buttonMapping);
  }

  attachZoomControl() {
    const container = document.createElement("div");
    container.id = "zoomcontrol";
    this.viewport.append($(container));

    ReactDOM.render(
      <ZoomControl onChange={(scale, point) => this.cork.setScale(scale / 100, point)} />,
      container
    );
  }

  moveBoard(xdirection, ydirection) {
    // We remove the focus when moving the board because
    // if you 1) click inside a note to edited it, 2) move
    // the board, 3) and then type, the browser will recenter
    // using its own method and put you in a funky state.
    FocusManager.blur();
    const moveStep = Config.get("moveStep");
    const diffScale = this.viewport.height() / this.viewport.width();
    const xdiff = moveStep * xdirection;
    const ydiff = moveStep * diffScale * ydirection;
    return this.cork.move(xdiff, ydiff);
  }

  // TODO: Should this function be in cork.js?
  recenterBoard() {
    return this.cork.recenter(
      this.viewport.width() / 2,
      this.viewport.height() / 2
    );
  }

  showLoading() {
    const div = $(document.createElement("div"));
    div.addClass("ei-loading-big");
    div.css({
      width: "100%",
      height: "100%"
    });

    NoticeManager.showModal({
      element: div,
      className: "loading"
    });
  }

  showReadOnlyMessage(message) {
    // For embeds, we want to encourage other users to get their own cork.
    // So for that case, we display the default message always.
    if (!message || Config.isEmbed()) {
      message = <div>
        {"You don't have permission to edit this board. Your changes will not be saved. "}
        <a href={Config.get("host")} target="_blank">Get your own!</a>
      </div>;
    }

    if (this.readOnlyDialog) {
      this.readOnlyDialog.remove();
    }

    this.readOnlyDialog = document.createElement("div");
    this.readOnlyDialog.id = "readonly-dialog";
    this.viewport.append($(this.readOnlyDialog));

    ReactDOM.render(
      <Dialog
        classes="read-only align-center"
        placement="full"
        visible={true}
        onClose={() => this.readOnlyDialog && this.readOnlyDialog.remove()}
      >
        {message}
      </Dialog>,
      this.readOnlyDialog
    );
  }

  showSubscriberLogout() {
    this.showLoading();
    const callback = () =>
      // Reload the page and have it set up permissions all over again.
      // Makes work of re-setting permissions much easier.
      window.location.reload();

    AccessControl.logout(callback);
  }

  showSubscriberLogin(callback, messageText, closeable = true) {
    const modal = new LoginSubscriberModal({
      messageText,
      closeable,
      success: () => {
        NoticeManager.hideModal();
        callback();
      }
    });

    modal.show();
  }

  showCorkLogin(callback, messageText) {
    const modal = new LoginCorkModal({
      messageText,
      closeable: false,
      success: callback
    });

    modal.show();
  }

  showCannotFindCorkError() {
    const offerDiv = $(document.createElement("div"));
    offerDiv.addClass("not-found");
    const h1 = $(document.createElement("h1"));
    h1.html("That's a negative Ghostrider.");
    const infoDiv = $(document.createElement("div"));
    infoDiv.html(
      `<br>We couldn't find your cork. <a href="${Config.get("host")}" target="_blank">Get a new one!</a>`
    );
    offerDiv.append(h1);
    offerDiv.append(infoDiv);
    return NoticeManager.showModal({ element: offerDiv });
  }

  addEditModeHandlers() {
    const itemDragHandler = () => {
      if (this.editModePopup != null) {
        return this.editModePopup.reposition();
      }
    };

    FocusManager.subscribe("lostfocus", lost => {
      lost.view.endEditMode();
      lost.on("change", itemDragHandler);
    });

    FocusManager.subscribe("gainedfocus", gained => {
      gained.view.beginEditMode();
      gained.on("change", itemDragHandler);
    });

    $(this.cork).on("pan zoom", () => {
      if (this.editModePopup != null) {
        this.editModePopup.reposition();
      }
    });
  }

  centerOnItemIfIdPresent() {
    const location = document.location.href;
    const lastSlash = location.substring(location.lastIndexOf("/") + 1);
    if (lastSlash.match(/^\d+$/) != null) {
      const itemId = parseInt(lastSlash);
      const item = Item.find(itemId);

      // Could be null if a deleted item id is passed.
      if (item != null) {
        const center = item.rectangle().center();
        return this.cork.setCenter(center);
      }
    }
  }

  removeTheVeil(fadeOut = true) {
    if (!fadeOut) {
      this.veil.remove();
      return;
    }

    return this.veil.animate(
      { opacity: "0" },
      {
        complete: () => this.veil.remove()
      }
    );
  }

  showViewport() {
    return this.viewport.css("opacity", "1");
  }

  fadeInViewport() {
    return this.viewport.animate({ opacity: "1" });
  }
}

export { Application };
