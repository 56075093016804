import React from "react";

class Dialog extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      visible: this.props.visible
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible != this.props.visible) {
      this.setState({ visible: this.props.visible });
    }
  }

  close() {
    this.props.onClose();
    this.setState({ visible: false });
  }

  render() {
    if (!this.state.visible) {
      return null;
    }

    const placement = this.props.placement ? `placement-${this.props.placement}` : null;

    return (
      <div className={`dialog ${placement} ${this.props.classes}`}>
        {this.props.children}
        <div className="remove" onClick={this.close.bind(this)}></div>
      </div>
    )
  }
}

export { Dialog };
