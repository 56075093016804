/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 */
import { Modal } from "./Modal";

import { NoticeManager } from "src/helpers/NoticeManager";

class HotKeysModal extends Modal {
  show(autoShown, skipDetails) {
    NoticeManager.showModal({
      header: "Hotkeys",
      tabs: {
        Editing: this.createEditingPane(),
        Navigation: this.createNavigationPane()
      },

      closeable: true,
      className: "hotkeys-modal white"
    });
  }

  createEditingPane(autoShown) {
    const pane = $(document.createElement("div"));
    pane.append(this.createFromString("ctrl/command + b = bold"));
    pane.append(this.createFromString("ctrl/command + i = italics"));
    pane.append(this.createFromString("ctrl/command + u = underline"));
    pane.append(this.createFromString("ctrl/command + s = strikethrough"));
    pane.append("<br>");
    pane.append(
      this.createFromString("ctrl/command + up = increase font size")
    );
    pane.append(
      this.createFromString("ctrl/command + down = decrease font size")
    );
    pane.append("<br>");
    pane.append(this.createFromString("ctrl/command + 1 = heading"));
    pane.append(this.createFromString("ctrl/command + 2 = subheading"));
    pane.append(this.createFromString("ctrl/command + 3 = normal text"));
    pane.append("<br>");
    pane.append(
      this.createFromString("tab = create bulleted list / indent list item")
    );
    pane.append(
      this.createFromString(
        "shift + tab = remove bulleted list / unindent list item"
      )
    );
    return pane;
  }

  createNavigationPane(autoShown) {
    const modal = this;
    const pane = $(document.createElement("div"));
    pane.append(
      this.createFromString("alt + plus = zoom in (supported browsers only)")
    );
    pane.append(
      this.createFromString("alt + minus = zoom out (supported browsers only)")
    );
    pane.append(
      this.createFromString("alt + 0 = normal zoom (supported browsers only)")
    );
    pane.append("<br>");
    pane.append(this.createFromString("alt + left = move left"));
    pane.append(this.createFromString("alt + right = move right"));
    pane.append(this.createFromString("alt + up = move up"));
    pane.append(this.createFromString("alt + down = move down"));
    pane.append("<br>");
    pane.append(this.createFromString("alt + escape = back to center"));
    pane.append("<br>");
    pane.append(this.createFromString("alt + spacebar = toggle chat window"));
    return pane;
  }

  // Purely to make adding/editing hotkeys easier.
  createFromString(str) {
    const modal = this;
    const row = $(document.createElement("div"));
    row.addClass("row");
    let foundEquals = false;
    let text = "";
    $.each(str.split(" "), function(index, fragment) {
      if (fragment === "+") {
        return row.append(modal.createPlus());
      } else if (fragment === "=") {
        row.append(modal.createEquals());
        return (foundEquals = true);
      } else if (foundEquals === false) {
        if (fragment.indexOf("/") >= 0) {
          const keys = fragment.split("/");
          return $.each(keys, function(ki, key) {
            row.append(modal.createKey(key));
            if (ki < keys.length - 1) {
              return row.append(modal.createSlash());
            }
          });
        } else {
          return row.append(modal.createKey(fragment));
        }
      } else {
        return (text += " " + fragment);
      }
    });

    row.append(this.createText(text));
    row.append("<br>");
    return row;
  }

  createKey(keyName) {
    const key = $(document.createElement("div"));
    key.addClass("keyboard-key");
    key.html(keyName);
    return key;
  }

  createPlus() {
    const plus = $(document.createElement("div"));
    plus.addClass("plus");
    plus.append("+");
    return plus;
  }

  createEquals() {
    const equals = $(document.createElement("div"));
    equals.addClass("equals");
    equals.append("=");
    return equals;
  }

  createSlash() {
    const slash = $(document.createElement("div"));
    slash.addClass("slash");
    slash.append("or");
    return slash;
  }

  createText(fragment) {
    const text = $(document.createElement("div"));
    text.addClass("text");
    text.append(fragment);
    return text;
  }
}

export { HotKeysModal };
