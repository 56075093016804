import { Config } from "src/config";
import { YesNoModal } from "./YesNoModal";

class TooManyUsersModal extends YesNoModal {
  constructor(team_id) {
    super();

    const in_line =
      Config.get("user").entrance_time_position() +
      1 -
      Config.get("board").account.plan.user_limit;

    this.options = {
      header: "Maximum Collaborators Reached",
      text: `You're <strong>#${in_line} in line.</strong> This window will disappear once a free spot opens up.`,
      className: "upgrade white onlyyes",

      yesfn: () => (window.location.href = `/pricing?team_id=${team_id}`),
      nofn: () => (window.location.href = `/pricing?team_id=${team_id}`),

      closefn() {},
      closeable: false,
      yesText: "Do you own this board? Get more collaborators!",
      //noText: "Upgrade for more users!"
      hideAfter: false
    };
  }
}

export { TooManyUsersModal };
