import { Config } from "src/config";
import { YesNoModal } from "./YesNoModal";

class AccountExceedsModal extends YesNoModal {
  constructor() {
    super();
    this.options = {
      header: "&nbsp;Account Exceeds Limit",
      text: "This board's account exceeds its plan limits. If you're receiving this message<br> in error, please contact us at <a href=\"mailto:support@noteapp.com\">support@noteapp.com</a>.",
      iconClass: "ei-warning",

      className: "onlyno",

      nofn() {
        window.open(`/pricing?team_id=${Config.get("accountId")}`);
      },

      closeable: false,
      hideAfter: false,
      noText: "Upgrade Account"
    };
  }
}

export { AccountExceedsModal };
