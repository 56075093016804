/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 */
import { Config } from "src/config";

import { NoteApp } from "src/api/v1";

import { CTAButton } from "../buttons/CTAButton";
import { NoticeManager } from "src/helpers/NoticeManager";
import { KeyCodes } from "../../base/KeyCodes";

class GetUpdatesModal {
  show() {
    const dialog = $(document.createElement("div"));
    dialog.append(
      "<span>Enter your email to get the latest information about " +
        Config.get("product_name") +
        ":</span><br><br>"
    );
    const textBox = $(document.createElement("input"));
    textBox.attr("type", "text");
    const accept = CTAButton.createSmall("Sign up", "inline");
    const submit = function() {
      const val = textBox.val().trim();
      const showError = function(error) {
        const element = dialog.children("span");
        element.hide();
        element.html(error);
        element.fadeIn();
        CTAButton.changeText(accept, "Try again");
        return CTAButton.enable(accept);
      };

      if (
        val === "" ||
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val) === false
      ) {
        return showError("Uh oh. Please enter a valid email address.");
      } else {
        const failure = () =>
          showError(
            "Oops. Error talking to " +
              Config.get("product_name") +
              ". Try again."
          );

        const success = function() {
          const thankYouDialog = $(document.createElement("div"));
          thankYouDialog.append("<h1>Thank you!</h1>");
          NoticeManager.showModal({
            className: "thankyou",
            element: thankYouDialog,
            closeable: false
          });

          return setTimeout(() => NoticeManager.hideModal(true), 1500);
        };

        CTAButton.changeText(accept, "One sec.");
        CTAButton.disable(accept);

        // Submit form.
        NoteApp.API.User.get_updates({
          email: val,
          success,
          failure
        });
      }
    };

    textBox.on("keypress", function(event) {
      if (event.which === KeyCodes.ENTER) {
        return submit();
      }
    });

    accept.on("click", submit);
    dialog.append(textBox);
    dialog.append(accept);
    NoticeManager.showModal({
      header:
        "<span class='icon ei-speech-bubble'></span> Get important news and updates.",
      className: "getupdates",
      element: dialog,
      closeable: true
    });

    return textBox.trigger("focus");
  }
}

export { GetUpdatesModal };
