import React from "react";
import { Spring, animated } from "@react-spring/web";

import { Config } from "src/config";

import { UserIcon } from "src/controls/UserIcon";
import { User } from "src/models/User";

class ChatStatus extends React.Component {
  maxDistinguishedUsers = 5;
  showMessageTimeout = null;
  messageSlideDuration = 300;

  constructor(props, context) {
    super(props, context);

    this.state = {
      latestMessage: null,
      anonUserCount: 0,
      distinguishedUserCount: 0,
      users: new Map(),
    };

    this.props.rtc.subscribe("marco", ({ user }) => this.addUser(user));
    this.props.rtc.subscribe("polo", ({ user }) => this.addUser(user));

    this.props.rtc.subscribe("message", ({ user, data }) =>
      this.showMessage(user, data.message)
    );

    this.props.rtc.subscribe("unsubscribed", ({ user, data }) => {
      this.removeUser(user);
    });
  }

  addUser(user) {
    // Check to see if we already have an icon for this user.
    // For instance, when we receive a polo from user #2 when
    // user #3 joins, after having already received marco from
    // user #2. If we already have an icon, do nothing.
    const { users } = this.state;

    if (users.has(user.clientId)) {
      return;
    }

    users.set(user.clientId, user);

    this.setState({ users }, () => this.updateUserCount());
  }

  removeUser(user) {
    const { users } = this.state;

    if (!users.has(user.clientId)) {
      return;
    }

    users.delete(user.clientId);

    this.setState({ users }, () => this.updateUserCount());
  }

  updateUserCount() {
    const anonymousUsers = User.findBy(
      "image_url",
      UserIcon.DEFAULT_USER_IMAGE_URL
    );

    let anonUserCount = anonymousUsers.count();

    // Take all the users, subtract the anonymous ones.
    let distinguishedUserCount = User.count - anonUserCount;

    // Remove yourself from the right count.
    if (Config.get("user").isDistinguished() === false) {
      anonUserCount -= 1;
    } else {
      distinguishedUserCount -= 1;
    }

    this.setState({ anonUserCount, distinguishedUserCount });
  }

  showMessage(user, message) {
    this.setState(
      {
        latestMessage: {
          user,
          message,
          deleted: false,
        },
      },
      () => {
        clearTimeout(this.showMessageTimeout);
        this.showMessageTimeout = setTimeout(() => {
          this.setState({
            latestMessage: {
              user,
              message,
              deleted: true,
            },
          });
        }, 2000);
      }
    );
  }

  render() {
    const { latestMessage, users, anonUserCount, distinguishedUserCount } =
      this.state;

    let latestMessageDiv;
    if (latestMessage) {
      const from = { top: 40, opacity: 0 };
      const to = { top: 0, opacity: 1 };

      latestMessageDiv = (
        <Spring
          from={latestMessage.deleted ? to : from}
          to={latestMessage.deleted ? from : to}
        >
          {(styles) => (
            <animated.div className="message-wrapper" style={styles}>
              <UserIcon user={latestMessage.user} />
              <div className="message">{latestMessage.message}</div>
            </animated.div>
          )}
        </Spring>
      );
    }

    let knownUserIcons = [];
    if (distinguishedUserCount > 0) {
      users.forEach((user, key) => {
        if (
          !user.isDistinguished() ||
          user === Config.get("user") ||
          knownUserIcons.length >= this.maxDistinguishedUsers
        ) {
          return;
        }

        knownUserIcons.push(
          <div key={key} className="distinguished-wrapper">
            <UserIcon user={user} />
          </div>
        );
      });
    } else {
      knownUserIcons = <div className="distinguished-wrapper"></div>;
    }

    let anonUserIcon;
    if (anonUserCount > 0) {
      anonUserIcon = (
        <Spring
          from={{ opacity: 0, display: "none" }}
          to={{ opacity: 1, display: "inline-block" }}
        >
          {(styles) => (
            <animated.div style={styles}>
              <UserIcon className="dummy" />
            </animated.div>
          )}
        </Spring>
      );
    }

    return (
      <div className="button chat-status nohover">
        <div className="icon-wrapper">
          {knownUserIcons}
          <div className="anon-wrapper">
            {anonUserIcon}
            <div className="anon-count">
              {anonUserCount > 1 ? `x ${anonUserCount}` : ""}
            </div>
          </div>
        </div>
        {latestMessageDiv}
      </div>
    );
  }
}

export { ChatStatus };
