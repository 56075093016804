import React from "react";
import { Scrollbars } from "react-custom-scrollbars";

import { Config } from "src/config";

import { Popup } from "src/controls/chrome/Popup";
import { UserIcon } from "src/controls/UserIcon";

import { escapeHtml } from "src/utils/Util";
import { KeyCodes } from "../../base/KeyCodes";

class Chat extends React.Component {
  currentRotation = 0;
  rotationTimeoutId = null;
  ROTATION_STEP = 15;
  ROTATION_STEP_TIME = 50; // milliseconds
  maxMessageLength = 512;
  chatClasses = {
    on: "ei-chat-activated",
    off: "ei-chat",
    error: "ei-chat-error",
    connecting: "ei-chat-connecting"
  };

  constructor(props, context) {
    super(props, context);

    this.buttonRef = React.createRef();
    this.chatLogRef = React.createRef();

    this.state = {
      chatClass: this.chatClasses.off,
      currentValue: "",
      messages: [],
      rotation: 0,
      unreadCount: 0,
      buttonWidth: null,
      popupVisible: false,
      hoveringPopup: false
    };

    try {
      this.notification = new Audio("/mp3/pop.mp3");
    } catch (e) {}

    // Respond to rtc events.
    this.props.rtc.subscribe("connecting", () => {
      this.setAsConnecting();
    });

    this.props.rtc.subscribe("connect", () => {
      this.setAsNotConnecting();
    });

    this.props.rtc.subscribe("message", ({ user, data }) => {
      this.addMessage(user, data.message);
      this.changeChatImage("on");

      if (user !== Config.get("user")) {
        try {
          this.notification.play();
        } catch (e) {}
      }
    });

    this.props.rtc.subscribe("error", event => {
      this.addMessage(
        null,
        "There was an error connecting to our collaboration servers. Refreshing the page usually does the trick. If you see this message repeatedly please contact us at support@noteapp.com."
      );
      this.changeChatImage("error");
    });

    document.addEventListener("keydown", this.keydownHandler.bind(this));
  }

  componentDidMount() {
    this.setState({ buttonWidth: this.buttonRef.current.offsetWidth });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible != this.props.visible) {
      if (!this.props.visible) {
        this.close();
      } else {
        this.show();
      }
    }
  }

  keydownHandler(event) {
    // Entering chat with the keyboard.

    // Ctrl+SPACE is the previous setting, and it's
    // here for users that are used to it. Alt+SPACE
    // is the new binding.
    if (event.altKey || event.ctrlKey) {
      switch (event.keyCode) {
        case KeyCodes.SPACE:
          event.stopPropagation();
          this.click();
          break;
      }
    }
  }

  changeChatImage(klass) {
    if (klass === "connecting") {
      // Hide the popup since it's attached to the image container...
      // == no funky rotation!
      this.setState(
        { popupVisible: false, chatClass: this.chatClasses[klass] },
        () => this.startRotation()
      );
      this.startRotation();
    } else {
      const newState = { chatClass: this.chatClasses[klass] };
      if (klass === "off") {
        newState.unreadCount = 0;
      }

      this.stopRotation();
      this.setState(newState);
    }
  }

  setAsConnecting() {
    this.changeChatImage("connecting");
  }

  setAsNotConnecting() {
    // Change the chat image to "off" since that's used for detecting
    // if we're connecting. TODO: can probably be refactored.
    this.changeChatImage("off");
  }

  isConnecting() {
    return this.state.chatClass === this.chatClasses.connecting;
  }

  startRotation() {
    clearTimeout(this.rotationTimeoutId);
    this.rotationTimeoutId = setTimeout(() => {
      this.currentRotation = (this.currentRotation + this.ROTATION_STEP) % 360;
      this.setState({ rotation: this.currentRotation });

      // Continue until stopRotation() is called.
      this.startRotation();
    }, this.ROTATION_STEP_TIME);
  }

  stopRotation() {
    clearTimeout(this.rotationTimeoutId);
    this.setState({ rotation: 0 });
  }

  addMessage(user, message) {
    const { messages, unreadCount } = this.state;
    message = escapeHtml(message);

    const lastMessage = messages[messages.length - 1];
    if (lastMessage && lastMessage.user === user) {
      lastMessage.message += "<br>" + message;
    } else {
      messages.push({ user, message });
    }

    this.setState({ messages, unreadCount: unreadCount + 1 }, () => {
      this.scrollChatLogToBottom();
    });
  }

  createMessageContainer(user, element, id, containerClass) {
    let icon;
    let wrapperClass;

    if (user != null) {
      if (user !== Config.get("user")) {
        wrapperClass = "other-user";
      }
      icon = <UserIcon user={user} />;
    }

    return (
      <div key={id} className={`message-container ${containerClass}`}>
        <div className={`wrapper ${wrapperClass}`}>
          {icon}
          {element}
        </div>
      </div>
    );
  }

  scrollChatLogToBottom() {
    if (this.chatLogRef.current) {
      this.chatLogRef.current.scrollToBottom();
    }
  }

  show() {
    this.setState({ popupVisible: true });
    this.changeChatImage("off");
    this.scrollChatLogToBottom();
  }

  close() {
    this.setState({ popupVisible: false, hoveringPopup: false });
  }

  handlePopupHover(hoveringPopup) {
    this.changeChatImage("off");
    this.scrollChatLogToBottom();
    this.setState({ hoveringPopup });
  }

  handleKeyPress(event) {
    // If we're in the middle of connecting, don't do anything.
    if (this.isConnecting()) {
      return;
    }

    if (event.which === KeyCodes.ENTER) {
      // If we're in the middle of connecting, don't do anything.
      // Here for added protection. A little crufty / !DRY.
      if (this.isConnecting()) {
        return;
      }

      const message = event.target.value;

      if (message.length > this.maxMessageLength) {
        this.addMessage(null, "That's too long. Try something shorter.");
      } else if (message.length > 0) {
        this.addMessage(Config.get("user"), message);
        this.props.rtc.broadcastMessage(message);
        this.setState({ currentValue: "" });
      }
    }

    this.changeChatImage("off");
  }

  click(event) {
    this.setState({ unreadCount: 0 }, () => {
      if (this.isConnecting()) {
        return;
      }

      this.props.onClick(event);
    });
  }

  getPopup() {
    const chatLog = [];

    this.state.messages.forEach(({ user, message }, index) => {
      chatLog.push(
        this.createMessageContainer(
          user,
          <div
            className="text"
            // Below is OK, because we sanitized the HTML in addMessage.
            dangerouslySetInnerHTML={{ __html: message }}
          ></div>,
          index
        )
      );
    });

    const input = this.createMessageContainer(
      Config.get("user"),
      <input
        type="text"
        className="input"
        placeholder="Say something..."
        value={this.state.currentValue}
        onClick={event => event.stopPropagation()}
        onChange={event => this.setState({ currentValue: event.target.value })}
        onKeyPress={this.handleKeyPress.bind(this)}
      ></input>,
      "input-container",
      "input-container"
    );

    const scrollBarStyle = {
      background: "rgba(200,200,200,0.25)",
      width: "8px",
      borderRadius: "7px"
    };

    const scrollStyle = {
      width: "calc(100% - 10px)",
      height: "calc(100% - 10px)"
    };

    return (
      <Popup
        buttonWidth={this.state.buttonWidth}
        type="chat left"
        visible={this.state.popupVisible}
        onMouseEnter={() => this.handlePopupHover(true)}
        onMouseLeave={() => this.handlePopupHover(false)}
      >
        <div className="chat-container user-select">
          <div className="log-wrapper">
            <Scrollbars
              ref={this.chatLogRef}
              className="log"
              style={scrollStyle}
              universal
              autoHeight
              renderThumbVertical={props => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    ...scrollBarStyle
                  }}
                ></div>
              )}
            >
              {chatLog}
            </Scrollbars>
          </div>
          {input}
        </div>
      </Popup>
    );
  }

  render() {
    const unreadCountDiv =
      this.state.unreadCount > 0 ? (
        <div className="unread-message-count">{this.state.unreadCount}</div>
      ) : null;

    const noHover = this.state.hoveringPopup ? "nohover" : "";

    return (
      <div
        key="chat"
        ref={this.buttonRef}
        onClick={this.click.bind(this)}
        className={`button chat ${noHover}`}
        title="Chat"
      >
        {this.getPopup()}
        <div className="wrapper">
          <div
            className={`image ${this.state.chatClass}`}
            style={{ transform: `rotate(${this.state.rotation}deg)` }}
          ></div>
          {unreadCountDiv}
        </div>
      </div>
    );
  }
}

export { Chat };
