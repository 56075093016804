import { Config } from "src/config";
import { YesNoModal } from "./YesNoModal";

class AccountClosedModal extends YesNoModal {
  constructor() {
    super();
    this.options = {
      header: "&nbsp;Account Closed",
      text: `This board's account has been closed. If you're receiving<br>this message in error, please contact us at support@${Config.get(
        "host"
      )}.`,
      iconClass: "ei-warning",

      // HACK: These are contradictory
      className: "onlyno onlyyes",
      nofn() {},

      closeable: false,
      hideAfter: false,
      noText: "Contact Support"
    };
  }

  contactSupport() {
    window.open(this.contactURL);
  }
}

export { AccountClosedModal };
