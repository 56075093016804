import React from "react";

import { Popup } from "src/controls/chrome/Popup";

import { PostIt, AVAILABLE_COLORS } from "src/models/PostIt";

class NoteColor extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.buttonRef = React.createRef();

    this.state = {
      current: "yellow",
      buttonWidth: null,
      popupVisible: false,
      hoveringPopup: false
    };
  }

  componentDidMount() {
    this.setState({ buttonWidth: this.buttonRef.current.offsetWidth });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible != this.props.visible) {
      if (!this.props.visible) {
        this.close();
      } else {
        this.show();
      }
    }
  }

  handleChangeColor(color) {
    this.setState({ current: color }, () => {
      this.props.onChange(color);
      this.close();
    });
  }

  show() {
    this.setState({ popupVisible: true });
  }

  close() {
    this.setState({ popupVisible: false, hoveringPopup: false });
  }

  handlePopupHover(hoveringPopup) {
    this.setState({ hoveringPopup });
  }

  getPopup() {
    const colorDivs = [];

    for (const color of AVAILABLE_COLORS) {
      const wrapper = (
        <div className="color-wrapper" key={color}>
          <div
            className={`color ${PostIt.iconClassForColor(color)}`}
            onClick={() => this.handleChangeColor(color)}
          ></div>
        </div>
      );

      colorDivs.push(wrapper);
    }

    return (
      <Popup
        buttonWidth={this.state.buttonWidth}
        type="note-color"
        visible={this.state.popupVisible}
        onMouseEnter={() => this.handlePopupHover(true)}
        onMouseLeave={() => this.handlePopupHover(false)}
      >
        {colorDivs}
      </Popup>
    );
  }

  render() {
    const noHover = this.state.hoveringPopup ? "nohover" : "";

    return (
      <div
        ref={this.buttonRef}
        onClick={this.props.onClick}
        className={`button note-color ${noHover}`}
        title="Note Color"
      >
        {this.getPopup()}
        <div
          className={`image ${PostIt.iconClassForColor(this.state.current)}`}
        ></div>
      </div>
    );
  }
}

export { NoteColor };
