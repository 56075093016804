import React, { useCallback } from "react";
import { Config } from "src/config";

const PoweredBy = () => {
  const onClick = useCallback(() => window.open("https://noteapp.com"));

  return (
    <div className="button text poweredby" onClick={onClick}>
      {`Powered by ${Config.get("product_name")}`}
    </div>
  );
};

export { PoweredBy };
