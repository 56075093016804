import { Config } from "src/config";
import { YesNoModal } from "./YesNoModal";

class AccountPastDueModal extends YesNoModal {
  constructor() {
    super();
    this.options = {
      header: "&nbsp;Account Past Due",
      text: "This board's account is past due. If you're receiving this message<br> in error, please contact us at <a href=\"mailto:support@noteapp.com\">support@noteapp.com</a>.",
      iconClass: "ei-warning",

      className: "onlyno",

      nofn() {
        window.open(`/dashboard/account/${Config.get("accountId")}/payments`);
      },

      closeable: false,
      hideAfter: false,
      noText: "Update Payment Information"
    };
  }
}

export { AccountPastDueModal };
