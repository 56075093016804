import { Config } from "src/config";
import { NoteApp } from "src/api/v1";

import { StatusCodes } from "src/base/StatusCodes";
import { Datastore } from "src/base/Datastore";

class ReadOnly extends Datastore {
  load(options) {
    NoteApp.API.Board.fetch_read_only({
      string_identifier: Config.get("string_identifier"),
      success: options.success,
      failure: options.failure
    });
  }

  save(options) {
    this.fakePermissionError(options.failure);
  }

  del(options) {
    this.fakePermissionError(options.failure);
  }

  fakePermissionError(failure) {
    const jqXHR = {
      status: StatusCodes.UNAUTHORIZED,
      statusText: "error",
      readyState: 4,
      responseText: JSON.stringify({})
    };

    failure(jqXHR, jqXHR.statusText, "Permission denied.");
    this.permissionErrorHandler();
  }
}

export { ReadOnly as ReadOnlyDatastore };
