import { Config } from "src/config";
import { YesNoModal } from "./YesNoModal";

class MustHaveAccountModal extends YesNoModal {
  constructor() {
    super();

    this.options = {
      header: "This board's old. Upgrade for free!",
      text: `You're using a board that's very old, and has limited support for new features and upgrades.<br><br>We've discontinued support for all old boards that weren't upgraded on <strong>February 1st, 2014</strong>. To keep this board, signup for a ${Config.get(
        "product_name"
      )} account using the button below and we'll automatically upgrade your board. Feel free to <a href='/contact'>contact us</a> with any questions.`,
      className: "upgrade white onlyyes",

      yesfn: () =>
        (window.location.href = `/users/sign_up?family=personal&board=${Config.get("string_identifier")}`),
      nofn() {},

      closefn() {},
      closeable: true,
      yesText: "Create account and upgrade board",
      noText: "",
      hideAfter: false
    };
  }
}

export { MustHaveAccountModal };
