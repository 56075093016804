/*
 * decaffeinate suggestions:
 * DS207: Consider shorter variations of null checks
 */
import * as Sentry from "@sentry/browser";

import { Config } from "src/config";

import { NoteApp } from "src/api/v1";

import { Datastore } from "src/base/Datastore";
import { StatusCodes } from "src/base/StatusCodes";

class Web extends Datastore {
  load(options) {
    NoteApp.API.Board.fetch({
      string_identifier: Config.get("string_identifier"),
      success: options.success,
      failure: options.failure
    });
  }

  // TODO: Overloaded. Either adds or saves the item. Needs to be refactored.
  save(options) {
    const datastore = this;
    this.requestStarted();

    options.success = options.success || function () { };
    options.failure = options.failure || function () { };

    const success = function (data, textStatus, jqXHR) {
      options.success(data, textStatus, jqXHR);
      datastore.afterSaveHandler(options.item);
    };

    const failure = function (jqXHR, textStatus, errorThrown) {
      options.failure(jqXHR, textStatus, errorThrown);

      try {
        const data = JSON.parse(jqXHR.responseText);

        if (data && jqXHR.status === StatusCodes.UNAUTHORIZED) {
          datastore.permissionErrorHandler(data.failure);
          return;
        }
      } catch { }

      if (jqXHR.status != 0) {
        Sentry.captureMessage("Save failure", {
          extra: {
            status: jqXHR.status,
            error: jqXHR.statusText,
            response: jqXHR.responseText
          }
        });
      }

      alert(
        "Couldn't save data: there was an error communicating with the server. This is usually due to network issues or maintenance downtime, and can easily be corrected by refreshing the page or waiting a short while for the issue to be resolved. If you have questions, please contact us on Twitter via @" +
        Config.get("product_name") +
        "."
      );;
    };

    const complete = (jqXHR, textStatus) => datastore.requestFinished();

    if (options.item.id != null) {
      NoteApp.API.Item.update({
        item: options.item,
        success(data, textStatus, jqXHR) {
          // Because save() is overloaded now that the API has changed,
          // we need to massage the response from API.Item.update to look
          // like it came from API.Board.addItems. # TODO: Stop doing this!
          data = { items: [{ id: data.id }] };
          success(data, textStatus, jqXHR);
        },
        failure,
        complete
      });
    } else {
      NoteApp.API.Board.addItem({
        string_identifier: Config.get("string_identifier"),
        item: options.item,
        success,
        failure,
        complete
      });
    }
  }

  del(options) {
    const datastore = this;
    this.requestStarted();
    options.success = options.success || function () { };

    options.failure = options.failure || function () { };

    const success = function (data, textStatus, jqXHR) {
      options.success(data, textStatus, jqXHR);
      datastore.afterDeleteHandler(options.item);
    };

    const failure = function (jqXHR, textStatus, errorThrown) {
      options.failure(jqXHR, textStatus, errorThrown);

      let data;
      try {
        data = JSON.parse(jqXHR.responseText);
      } catch (e) { }

      if (data && jqXHR.status === StatusCodes.UNAUTHORIZED) {
        datastore.permissionErrorHandler(data.failure);
      } else {
        alert(
          "Failure deleting. This likely means there was a problem talking to the server. Please contact support@noteapp.com if this continues to be a problem."
        );
      }
    };

    const complete = (jqXHR, textStatus) => datastore.requestFinished();

    NoteApp.API.Item.destroy({
      item_id: options.item.id,
      success,
      failure,
      complete
    });
  }
}

export { Web as WebDatastore };
