import React from "react";

import { Config } from "src/config";

import { Popup } from "src/controls/chrome/Popup";

import { GetUpdatesModal } from "src/controls/modals/GetUpdatesModal";
import { HotKeysModal } from "src/controls/modals/HotKeysModal";

class Help extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.buttonRef = React.createRef();

    this.state = {
      buttonWidth: null,
      popupVisible: false,
      hoveringPopup: false
    };
  }

  componentDidMount() {
    this.setState({ buttonWidth: this.buttonRef.current.offsetWidth });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible != this.props.visible) {
      if (!this.props.visible) {
        this.close();
      } else {
        this.show();
      }
    }
  }

  show() {
    this.setState({ popupVisible: true });
  }

  close() {
    this.setState({ popupVisible: false, hoveringPopup: false });
  }

  handlePopupHover(hoveringPopup) {
    this.setState({ hoveringPopup });
  }

  getPopup() {
    return (
      <Popup
        buttonWidth={this.state.buttonWidth}
        type="right"
        visible={this.state.popupVisible}
        onMouseEnter={() => this.handlePopupHover(true)}
        onMouseLeave={() => this.handlePopupHover(false)}
      >
        <ul className="options">
          <li
            id="hotkeys"
            className="hotkeys clickable image-li"
            onClick={() => new HotKeysModal().show()}
          >
            <div className="wrapper">
              <div className="image-div ei-popup-keys">Hotkeys</div>
            </div>
          </li>
          <li id="sep-1" className="separator">
            <div className="wrapper"></div>
          </li>
          <li
            id="blog"
            className="clickable"
            onClick={() =>
              window.open(`${Config.get("host")}/blog`)
            }
          >
            <div className="wrapper">
              {Config.get("product_name") + " Blog"}
            </div>
          </li>
          <li
            id="updates"
            className="clickable"
            onClick={() => new GetUpdatesModal().show()}
          >
            <div className="wrapper">Newsletter</div>
          </li>
          <li id="sep-2" className="separator">
            <div className="wrapper"></div>
          </li>
          <li
            id="contact"
            className="clickable"
            onClick={() =>
              window.open(`${Config.get("host")}/contact`)
            }
          >
            <div className="wrapper">Contact Support &rarr;</div>
          </li>
        </ul>
      </Popup>
    );
  }

  render() {
    const noHover = this.state.hoveringPopup ? "nohover" : "";

    return (
      <div
        ref={this.buttonRef}
        onClick={this.props.onClick}
        className={`button help ${noHover}`}
        title="Help"
      >
        {this.getPopup()}
        <div className="image ei-help"></div>
      </div>
    );
  }
}

export { Help };
