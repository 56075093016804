import React from "react";
import styled from "styled-components";
import { Range, Direction } from "react-range";

import { Config } from "src/config";

import { KeyCodes } from "src/base/KeyCodes";
import { Point } from "src/base/Point";

const Container = styled.div`
  width: 40px;
  position: fixed;
  left: 10px;
  top: 10px;
  z-index: 100000;
  /* Same level as the bar. */
  padding-top: 20px;
  padding-bottom: 20px;
`;

const Slider = styled.div`
  width: 2px;
  height: 80px;
  background: rgb(240, 240, 240);
  position: relative;
  margin-left: auto;
  margin-right: auto;
  border-bottom: solid 1px #444;
  border-left: solid 1px #aaa;
  border-top: solid 1px #aaa;
  border-right: solid 1px #444;
  border-radius: 2px;
  overflow: visible;
`;

const Nub = styled.div`
  width: 40px;
  height: 40px;
  background-position: center center;
  background-repeat: no-repeat;
`;

class ZoomControl extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      values: [Config.get("maximumScale")]
    };
  }

  componentDidMount() {
    document.addEventListener("wheel", this.wheelHandler.bind(this));
    document.addEventListener("keydown", this.keydownHandler.bind(this));
  }

  componentWillUnmount() {
    document.removeEventListener("wheel", this.wheelHandler.bind(this));
    document.removeEventListener("keydown", this.keydownHandler.bind(this));
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.scale !== this.props.scale) {
      this.setScale(this.props.scale);
    }
  }

  wheelHandler(event) {
    // Zooming in with the scroll wheel
    if (event.altKey) {
      event.stopPropagation();
      const point = new Point(event.pageX, event.pageY);
      if (event.wheelDelta > 0) {
        this.zoomIn(point);
      } else {
        this.zoomOut(point);
      }
    }
  }

  keydownHandler(event) {
    // Zooming in with the keyboard
    if (event.altKey) {
      switch (event.keyCode) {
        case KeyCodes.PLUS:
        case KeyCodes.codeFor("="):
          event.stopPropagation();
          this.zoomIn();
          break;
        case KeyCodes.MINUS:
        case KeyCodes.codeFor("-"):
          event.stopPropagation();
          this.zoomOut();
          break;
        case KeyCodes.codeFor("0"):
          event.stopPropagation();
          this.handleSliderChange([100]);
          break;
      }
    }
  }

  zoomIn(point) {
    const { values } = this.state;
    const currentScale = values[0];

    const newScale = Math.min(
      currentScale + Config.get("scaleStep"),
      Config.get("maximumScale")
    );

    this.setState({ values: [newScale] }, () =>
      this.props.onChange(values[0], point)
    );
  }

  zoomOut(point) {
    const { values } = this.state;
    const currentScale = values[0];

    const newScale = Math.max(
      currentScale - Config.get("scaleStep"),
      Config.get("minimumScale")
    );

    this.setState({ values: [newScale] }, () =>
      this.props.onChange(values[0], point)
    );
  }

  handleSliderChange(values) {
    this.setState({ values }, () => this.props.onChange(values[0]));
  }

  setScale(scale) {
    this.setState({ values: [scale] });
  }

  render() {
    return (
      <Container title="Zoom">
        <Range
          min={Config.get("minimumScale")}
          max={Config.get("maximumScale")}
          values={this.state.values}
          onChange={values => this.handleSliderChange(values)}
          direction={Direction.Up}
          renderTrack={({ props, children }) => (
            <Slider {...props}>{children}</Slider>
          )}
          renderThumb={({ props }) => (
            <Nub {...props} className="ei-note-white-noalpha" />
          )}
        />
      </Container>
    );
  }
}

export { ZoomControl };
