import * as Sentry from '@sentry/browser';

import consumer from "channels/consumer";

import { Config } from "src/config";
import { EventManager } from "src/helpers/EventManager";

import { User } from "src/models/User";

import { debounce } from "lodash";

class RTC {
  // Allow components to subscribe to updates.
  eventManager;

  // Client ID used by ActionCable internally (to cache presence data).
  clientId;

  // Channel connections. One's the public channel for
  // the board. The other's the "private" channel for the user.
  boardChannel;
  privateChannel;

  constructor(api_key) {
    this.eventManager = new EventManager();

    this.boardChannel = {
      channel: "BoardChannel",
      board: api_key
    };

    this.privateChannel = {
      channel: "UserChannel",
      user: Config.get("user").clientId
    };

    this._debouncedBroadcastPolo = debounce(this.broadcastPolo.bind(this));
  }

  connect() {
    // Don't allow rtc to connect if rtc is disabled.
    // Used for testing over a proxy on iOS.
    if (!Config.get("rtc_enabled")) {
      return;
    }

    // Subscribe to our user channel.
    // Note: actions are handled just like over the public channel. Should they be?
    if (!this.priv_connection) {
      this.priv_connection = consumer.subscriptions.create(
        this.privateChannel,
        {
          connected() {
            Sentry.addBreadcrumb({
              category: 'http',
              message: 'Connected to private RTC',
              level: "info"
            });

            this.send({
              command: "subscribed"
            });
          },
          disconnected: () => {
            Sentry.addBreadcrumb({
              category: 'http',
              message: 'Reconnecting to private RTC',
              level: "info"
            });

            this.eventManager.dispatch("connecting");
          },
          rejected: () => {
            Sentry.captureException(new Error("Rejected from private RTC"));

            this.eventManager.dispatch("error");
          },
          received: data => {
            this.processPayload(data, this.privateChannel);
          }
        }
      );
    }
  }

  processPayload(action, channel) {
    // If we're processing a payload that was sent by us, perform
    // special functions for certain commands; else do nothing.
    // We only care about subscribe on the private channel.

    const subscribedToPrivate =
      action.command === "subscribed" && channel === this.privateChannel;

    if (subscribedToPrivate) {
      // This is the user's own subscribe event. Let's trigger
      // a "connect" as that was needed -- and used -- previously
      // by the old RTC system. Here, it will mean that everything
      // needed for setting up a connection has finished.
      this.clientId = action.user;

      Config.get("user").set("rtc_user_id", this.clientId);

      // Subscribe to the channel for this board.
      if (!this.connection) {
        this.connection = consumer.subscriptions.create(this.boardChannel, {
          connected: () => {
            Sentry.addBreadcrumb({
              category: 'http',
              message: 'Connected to board RTC',
              level: "info"
            });

            // Tell the app we're all good.
            this.eventManager.dispatch("connect");

            // Now tell everyone else we're here.
            this.broadcastMarco();
          },
          disconnected: () => {
            Sentry.addBreadcrumb({
              category: 'http',
              message: 'Reconnecting to board RTC',
              level: "info"
            });

            this.eventManager.dispatch("connecting");
          },
          rejected: () => {
            Sentry.captureException(new Error("Rejected from board RTC"));

            this.eventManager.dispatch("error");
          },
          received: data => {
            this.processPayload(data, this.boardChannel);
          }
        });
      }
    }

    if (action.user === this.clientId) {
      return;
    }

    // Alright. Process commands sent by others.
    switch (action.command) {
      case "marco":
      case "polo":
      case "update":
      case "delete":
      case "message":
        var userInformation = action.data.user;
        var { clientId } = userInformation;

        // The client id persists in the case of server restarts,
        // assuming the browser doesn't restart.
        var user = User.find(clientId);
        if (!user) {
          user = User.findBy("rtc_user_id", action.user).first();

          // If we're still null, create it.
          if (!user) {
            user = User.create();
          }
        }
        user.set(userInformation);

        // Always set the rtc_user_id just in case it changes
        // (i.e., server restart).
        user.set("rtc_user_id", action.user);
        this.eventManager.dispatch(action.command, { user, data: action.data });
        if (action.command === "marco") {
          this._debouncedBroadcastPolo();
        }
        break;
      case "subscribed":
        user = User.create({ rtc_user_id: action.user });
        this.eventManager.dispatch(action.command, { user, data: action.data });
        break;
      case "unsubscribed":
        // We don't have control of the unsubscribe action, so we get the user
        // via the rtc_user_id instead.
        user = User.findBy("rtc_user_id", action.user).first();

        // Kill it with fire.
        if (user != null) {
          user.remove();
        } else {
          // Ensure there's ALWAYS a user.
          user = new User({ rtc_user_id: action.user });
        }
        this.eventManager.dispatch(action.command, { user, data: action.data });
        break;
      default:
        console.log("Can't process command: " + action.command);
        break;
    }
  }

  publish(action) {
    // Don't publish anything if rtc is disabled.
    // Used for testing over a proxy on iOS.
    if (!Config.get("rtc_enabled")) {
      return;
    }

    action.user = this.clientId;

    if (this.connection) {
      this.connection.send(action);
    }
  }

  // Tell everyone on the board you exist.
  broadcastMarco() {
    const action = {
      command: "marco",
      data: {
        user: Config.get("user").publicInformation()
      }
    };

    this.publish(action);
  }

  // Broadcast that you exist. This is usually sent in response
  // to a "marco".
  broadcastPolo() {
    const action = {
      command: "polo",
      data: {
        user: Config.get("user").publicInformation()
      }
    };

    this.publish(action);
  }

  // Tell everyone you updated a an item.
  broadcastUpdate(item) {
    const action = {
      command: "update",
      data: {
        item: item.serialize(),
        user: Config.get("user").publicInformation()
      }
    };

    this.publish(action);
  }

  // Tell everyone you deleted an item.
  broadcastDelete(item) {
    const action = {
      command: "delete",
      data: {
        itemId: item.id,
        user: Config.get("user").publicInformation()
      }
    };

    this.publish(action);
  }

  // Tell everyone you sent a message.
  broadcastMessage(message) {
    const action = {
      command: "message",
      data: {
        user: Config.get("user").publicInformation(),
        message
      }
    };

    this.publish(action);
  }

  subscribe(event, handler) {
    this.eventManager.subscribe(event, handler);
  }
}

export { RTC };
