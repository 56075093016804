import React from "react";
import Draggable from "react-draggable";

import { debounce } from "lodash";

import { Item } from "src/models/Item";
import { Rectangle } from "src/base/Rectangle";

import { Dialog } from "src/controls/chrome/Dialog";

class Map extends React.Component {
  // For the minimap, we store a reference to a div instead
  // of the item itself.
  scaleFactor;

  // A rectangle encompassing all of the items.
  rectangle;
  width = 320;
  height = 200;
  minItemWidth = 4;
  minItemHeight = 4;
  minBoundsWidth = 15;
  minBoundsHeight = 8;

  constructor(props, context) {
    super(props, context);

    this.state = {
      popupVisible: false,
      hoveringPopup: false,
      centerStyle: {},
      boundStyle: {},
      position: {
        x: 0,
        y: 0,
      },
      items: {},
    };

    this._debouncedItemChanged = debounce(this.itemChanged.bind(this));
  }

  componentDidMount() {
    this.reinitialize();

    // Handle specific cork events.
    Item.on("add", (event, item) => this.addItem(item));
    Item.on("remove", (event, item) => this.removeItem(item));

    // Drag is deprecated I believe?
    $(this.props.cork).on("pan", () => this.adjustBounds());
    $(this.props.cork).on("drag", () => this.adjustBounds());
    $(this.props.cork).on("dragstop", () => this.adjustBounds());
    $(this.props.cork).on("resize", () => this.adjustBounds());
    $(this.props.cork).on("zoom", () => this.adjustBounds());
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible != this.props.visible) {
      if (!this.props.visible) {
        this.close();
      } else {
        this.show();
      }
    }
  }

  reinitialize() {
    this.rectangle = new Rectangle(0, 0, 0, 0);

    // Add items and compute initial bounds.
    Item.each((item) => this.addItem(item));

    this.rescale();
  }

  // Adjust the bounds based upon the size and position of the board.
  adjustBounds() {
    const rectangle = this.props.cork.getViewingRectangle();

    // Adjust top left of the viewing rectangle to be within the bounds of the center point.
    const topLeft = rectangle.topLeft();

    // Scale it down to our own scale factor.
    const left = topLeft.x * this.scaleFactor;
    const top = topLeft.y * this.scaleFactor;
    let width = rectangle.width() * this.scaleFactor;
    let height = rectangle.height() * this.scaleFactor;
    width = Math.max(this.minBoundsWidth, width);
    height = Math.max(this.minBoundsHeight, height);

    // Ensure the bounds div is up front.
    const zIndexes = Object.values(this.state.items).map((item) => item.z);
    let zIndex = Math.max.apply(Math, zIndexes) + 1;
    zIndex = isFinite(zIndex) ? zIndex : 0;

    this.setState({
      ...this.state,
      boundStyle: {
        left,
        top,
        width,
        height,
        zIndex,
      },
    });
  }

  // Adjust the cork based on the center of the bounds relative to the
  // center of the map.
  adjustCork() {
    let { left, top, width, height } = this.state.boundStyle;
    left = (left + width / 2) / this.scaleFactor;
    top = (top + height / 2) / this.scaleFactor;
    left = left * this.props.cork.getScale();
    top = top * this.props.cork.getScale();

    // Send our unscaled local coordinates to the cork for centering.
    this.props.cork.setCenter({ x: left, y: top });
  }

  // Reposition the bounds based on a point relative to the top left
  // of the map wrapper.
  repositionBounds({ x, y }) {
    const { boundStyle, centerStyle } = this.state;

    if (x < 0) {
      x = 0;
    }
    const maxWidth = this.width - boundStyle.width;
    if (x > maxWidth) {
      x = maxWidth;
    }

    if (y < 0) {
      y = 0;
    }
    const maxHeight = this.height - boundStyle.height;
    if (y > maxHeight) {
      y = maxHeight;
    }

    this.setState(
      {
        ...this.state,
        position: {
          x,
          y,
        },
        // Translate the point to be relative to the center.
        boundStyle: {
          ...this.state.boundStyle,
          left: x - centerStyle.left,
          top: y - centerStyle.top,
        },
      },
      this.adjustCork.bind(this)
    );
  }

  handleClick(event) {
    // Reposition the map on click.
    if (!event || !event.nativeEvent) {
      return;
    }
    if (event.persist) {
      event.persist();
    }

    const { offsetX, offsetY } = event.nativeEvent;
    const { boundStyle } = this.state;
    this.repositionBounds({
      x: offsetX - boundStyle.width / 2,
      y: offsetY - boundStyle.height / 2,
    });
  }

  handleDrag(event, position) {
    const { x, y } = position;
    this.repositionBounds({ x, y });
  }

  expandRectangle(item) {
    const itemRectangle = item.rectangle();

    if (!this.rectangle.contains(itemRectangle)) {
      this.rectangle.include(itemRectangle);
      this.rescale();
    }
  }

  // Rescale the whole minimap based on size and position of the board.
  // This computes a new scale factor, and goes through every item on the
  // board and resizes it appropriately. This is O(n). Use sparingly.
  rescale() {
    // Get dimensions of the cork. Note this is equivalent to the viewport.
    const boardWidth = this.props.cork.width();
    const boardHeight = this.props.cork.height();

    // Ensure bounds are equal to maintain ratios.
    // This algorithm expects -x1, -y1, x2, y2
    if (Math.abs(this.rectangle.x1) !== this.rectangle.x2) {
      const xMax = Math.max(Math.abs(this.rectangle.x1), this.rectangle.x2);
      this.rectangle.x1 = -xMax;
      this.rectangle.x2 = xMax;
    }
    if (Math.abs(this.rectangle.y1) !== this.rectangle.x2) {
      const yMax = Math.max(Math.abs(this.rectangle.y1), this.rectangle.y2);
      this.rectangle.y1 = -yMax;
      this.rectangle.y2 = yMax;
    }

    // Get the bounds width, but add half a window of padding on each side.
    const boundsWidth = this.rectangle.width() + boardWidth;
    const boundsHeight = this.rectangle.height() + boardHeight;

    // Compute and save a scale factor for later calculations.
    this.scaleFactor = Math.min(
      this.width / boundsWidth,
      this.height / boundsHeight
    );

    // Recenter, like cork.js
    this.setState(
      {
        ...this.state,
        centerStyle: {
          left: this.width / 2,
          top: this.height / 2,
        },
      },
      this.adjustBounds.bind(this)
    );
  }

  itemChanged(item) {
    const { items } = this.state;
    items[item.clientId] = item;
    this.expandRectangle(item);
    this.setState({ ...this.state, items });
  }

  // Add an item to the minimap.
  addItem(item) {
    const { items } = this.state;
    items[item.clientId] = item;

    item.on("change", () => this._debouncedItemChanged(item));

    this.expandRectangle(item);
    this.setState({ ...this.state, items });
  }

  // Remove an item from a minimap.
  removeItem(item) {
    const { items } = this.state;
    delete items[item.clientId];

    // Totally start this map over because we'd have to perform an
    // O(n) operation anyway to scale down the board.
    this.setState({ ...this.state, items }, () => this.reinitialize());
  }

  show() {
    this.setState({ popupVisible: true });
  }

  close() {
    this.setState({ popupVisible: false, hoveringPopup: false });
  }

  renderItems() {
    // Adjust the size and position of a specific item.
    const getItemStyle = (item) => {
      const newX = item.x * this.scaleFactor;
      const newY = item.y * this.scaleFactor;
      let newWidth = item.object.width * this.scaleFactor;
      let newHeight = item.object.height * this.scaleFactor;
      newHeight = Math.max(this.minItemHeight, newHeight);
      newWidth = Math.max(this.minItemWidth, newWidth);

      return {
        width: newWidth,
        height: newHeight,
        left: newX,
        top: newY,
        zIndex: item.z,
      };
    };

    const { items } = this.state;
    const divs = [];

    Object.keys(items).forEach((key) => {
      const item = items[key];

      const div = (
        <div
          key={`map-${item.clientId}`}
          className={`minimap-item ${item.objectType} ${item.object.color}`}
          style={getItemStyle(item)}
        >
          <div className="wrapper"></div>
        </div>
      );

      divs.push(div);
    });

    return divs;
  }

  getPopup() {
    const { boundStyle, position } = this.state;
    const draggableBoxStyle = {
      top: -position.y,
      left: -position.x,
      width: this.width,
      height: this.height,
      zIndex: boundStyle.zIndex + 1,
    };

    return (
      <Dialog
        classes="map"
        placement="right"
        onClose={this.props.onClick}
        visible={this.state.popupVisible}
      >
        <div
          className="minimap"
          style={{ width: this.width, height: this.height }}
        >
          <Draggable
            onStart={this.handleClick.bind(this)}
            onDrag={this.handleDrag.bind(this)}
            position={position}
          >
            <div className="drag-box" style={draggableBoxStyle} />
          </Draggable>
          {/* Like the cork, create a center point which contains everything. */}
          <div className="center" style={this.state.centerStyle}>
            {this.renderItems()}
            {/* Create a bounds rectangle that is treated as an item within the mini-cork. */}
            <div className="bounds" style={boundStyle}>
              <div className="wrapper"></div>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }

  render() {
    const noHover = this.state.hoveringPopup ? "nohover" : "";

    return (
      <div className={`button map ${noHover}`} title="Board Overview">
        {this.getPopup()}
        <div onClick={this.props.onClick} className={`image ei-map`}></div>
      </div>
    );
  }
}

export { Map };
