class Datastore {
  // This is the subclass of all datastores. It's here so that
  // A) subclasses can inherit from it, and
  // B) to throw errors very violently when a datastore is behaving
  //    improperly.
  requestCount = 0;

  permissionErrorHandler;
  afterSaveHandler;
  afterDeleteHandler;

  constructor({ permissionErrorHandler, afterSaveHandler, afterDeleteHandler } = {}) {
    this.permissionErrorHandler = permissionErrorHandler || this.defaultHandler;
    this.afterSaveHandler = afterSaveHandler || this.defaultHandler;
    this.afterDeleteHandler = afterDeleteHandler || this.defaultHandler;
  }

  defaultHandler() {}

  load() {
    throw "Datastore must implement a load() function.";
  }

  save() {
    throw "Datastore must implement a save() function.";
  }

  del() {
    throw "Datastore must implement a del() function.";
  }

  requestStarted() {
    this.requestCount += 1;
  }

  requestFinished() {
    this.requestCount -= 1;

    // For safety.
    if (this.requestCount < 0) {
      this.requestCount = 0;
    }
  }

  isProcessingRequests() {
    return this.requestCount > 0;
  }
}

export { Datastore };
