import { LoginSubscriberModal } from "./LoginSubscriberModal";

import { Config } from "src/config";

import { KeyCodes } from "src/base/KeyCodes";
import { StatusCodes } from "src/base/StatusCodes";

import { AccessControl } from "src/helpers/AccessControl";
import { NoticeManager } from "src/helpers/NoticeManager";

import { CTAButton } from "src/controls/buttons/CTAButton";

class LoginCorkModal extends LoginSubscriberModal {
  show() {
    NoticeManager.showModal({
      header: "Password Required",
      tabs: {
        Creds: this.createCorkLoginTab(),
        "Forgot?": this.createForgotPasswordTab()
      },

      className: "login",
      closeable: false
    });

    NoticeManager.hideModalTabs();
    this.afterShow();
  }

  createCorkLoginTab() {
    const dialog = $(document.createElement("div"));

    const email = $(document.createElement("input"));
    email.addClass("email");
    email.attr("type", "text");
    email.attr("placeholder", "Email address");
    email.hide();
    const password = $(document.createElement("input"));
    password.addClass("password");
    password.attr("type", "password");
    password.attr("placeholder", "Password");
    const login = CTAButton.createSmall("Access board", "inline submit");
    const ownerTextLink = $(document.createElement("a"));
    ownerTextLink.addClass("owner-text");
    ownerTextLink.html("Collaborator Login");
    const ownerWrapper = $(document.createElement("div"));
    ownerWrapper.addClass("owner-wrapper");
    ownerWrapper.append(ownerTextLink);

    let toggled = false;

    const toggle = event => {
      if (email.is(":hidden")) {
        email.fadeIn(200);
      } else {
        email.hide();
      }
      if (toggled === true) {
        toggled = false;
        ownerTextLink.text("Collaborator Login");
        CTAButton.changeText(login, "Access board");
        NoticeManager.setModalHeaderText("Password Required");
        NoticeManager.hideModalTabs();
        changeMessage(this.messageText);
      } else {
        toggled = true;
        ownerTextLink.text("Login as Guest");
        CTAButton.changeText(login, "Login");
        NoticeManager.setModalHeaderText("Login");
        NoticeManager.showModalTabs();

        email.focus();
      }
      return event.stopImmediatePropagation();
    };

    ownerWrapper.on("click", toggle);

    const message = $(document.createElement("div"));
    message.addClass("message ei-pushpin");
    message.html(this.messageText || "");
    if (this.messageText == null) {
      message.hide();
    }
    var changeMessage = (text, force) => {
      if (!force && message.html() === text) {
        return;
      }
      message.html(text);
      if (message.is(":hidden")) {
        return message.animate({
          height: "toggle",
          opacity: "toggle"
        });
      } else {
        message.hide();
        return message.fadeIn();
      }
    };

    const sendRequest = () => {
      CTAButton.disable(login);
      CTAButton.changeText(login, "Please wait...");

      const failure = (jqXHR, textStatus, errorThrown) => {
        if (jqXHR.status === StatusCodes.RATE_LIMIT) {
          changeMessage("Please slow down.");
        } else {
          const data = JSON.parse(jqXHR.responseText);

          if (data && jqXHR.status === StatusCodes.UNAUTHORIZED) {
            changeMessage(data.error, true);
          } else {
            changeMessage("Server error. Please retry.");
          }
        }

        CTAButton.changeText(login, "Try again");
        return CTAButton.enable(login);
      };

      // If it's the owner, login as normal. Otherwize login to the cork.
      if (toggled === true) {
        return AccessControl.login(
          email.val(),
          password.val(),
          this.success,
          failure
        );
      } else {
        return AccessControl.loginCork(
          Config.getIdentifier(),
          password.val(),
          this.success,
          failure
        );
      }
    };

    login.on("click", sendRequest);
    email.on("keypress", event => {
      if (event.which === KeyCodes.ENTER) {
        return sendRequest();
      }
    });

    password.on("keypress", event => {
      if (event.which === KeyCodes.ENTER) {
        return sendRequest();
      }
    });

    dialog.append(message);
    dialog.append(email);
    dialog.append(password);
    dialog.append(ownerWrapper);
    dialog.append(login);
    return dialog;
  }
}

export { LoginCorkModal };
