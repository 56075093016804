import { Config } from "src/config";

import { Container } from "src/base/Container";
import { Point } from "src/base/Point";
import { Rectangle } from "src/base/Rectangle";

import { FocusManager } from "src/helpers/FocusManager";
import { TouchDetector } from "src/helpers/TouchDetector";
import { UploadManager } from "src/helpers/UploadManager";

import { Item } from "src/models/Item";

import { Device } from "src/utils/Device";
import { setTransform } from "src/utils/Util";

import { UI } from "src/views/UI";
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 */
class Cork extends Container {
  dragWindow;
  center;
  viewport;
  container;
  maxZIndex = 0;
  pinching = false;

  // Initial scale of the board.
  scale = 1.0;

  // Minimum zoom padding when calling zoomTo().
  minimumZoomPadding = 50;
  zoomAnimationDuration = 170;

  // Values to hold the actual width and height of the background image.
  // We use Javascript to get the background image and then set these values
  // in the resetBackgroundImageDimensions() function.
  backgroundImageWidth;
  backgroundImageHeight;

  backgroundClass;

  constructor(viewport, point, scale, backgroundClass) {
    if (backgroundClass == null) {
      backgroundClass = "ei-cork-bg";
    }
    super();

    point = point || new Point(0, 0);
    scale = scale || this.scale;
    this.viewport = viewport;
    this.container = $(document.createElement("div"));
    this.container.addClass("board");
    this.dragWindow = $(document.createElement("div"));
    this.dragWindow.addClass("drag-window");
    this.container.append(this.dragWindow);
    this.viewport.append(this.container);
    this.center = $(document.createElement("div"));
    this.center.addClass("center");
    this.viewport.append(this.center);
    this.setCenter(point);
    this.setScale(scale);
    this.setWallpaper(backgroundClass, () => this.repositionBackground());

    this.bindHandlers();

    Item.on("add", (event, item) => this.addItem(item));
    Item.on("remove", (event, item) => this.removeItem(item));

    Item.each(item => this.addItem(item));
  }

  setWallpaper(backgroundClass, complete) {
    this.container.removeClass(this.backgroundClass);
    this.backgroundClass = backgroundClass;
    this.container.addClass(this.backgroundClass);

    return this.resetBackgroundImageDimensions(() => {
      this.repositionBackground();
      if (complete != null) {
        return complete();
      }
    });
  }

  // Since it was already in CSS, this should pull from cache.
  resetBackgroundImageDimensions(complete) {
    const cork = this;
    complete = complete || function () { };

    const image = new Image();
    $(image).on("load", function () {
      cork.backgroundImageWidth = image.width;
      cork.backgroundImageHeight = image.height;
      return complete();
    });

    return (image.src = this.container
      .css("background-image")
      .replace(/"/g, "")
      .replace(/url\(|\)$/g, ""));
  }

  hasHandlersBound() {
    // We can detect if handlers are bound by seeing if the cork has the
    // ui-draggable class added by the draggable() function.
    //return this.container.hasClass("ui-draggable");
    return this.dragWindow.hasClass("ui-draggable");
  }

  //
  //	 * bindHandlers: function
  //	 *
  //	 * Used to turn on handlers when mouse enters the cork (i.e., leaves an item).
  //
  bindHandlers() {
    if (this.hasHandlersBound()) {
      return;
    }
    UI.makeDraggable(this.dragWindow, { clickHandler: this });

    // TODO: This can be refactored to use only the difference
    // in mouse position. Was original built with jQuery draggable.
    let startingMousePosition = null;
    let currentMousePosition = null;
    let startingPosition = null;
    const repositionDuringDrag = () => {
      const position = this.dragWindow.position();
      const deltaX = position != null ? position.left : 0;
      const deltaY = position != null ? position.top : 0;

      // TODO TODO TODO TODO TODO TODO TODO TODO TODO TODO TODO TODO TODO
      // Should replace Device.isMobile() here with detection for transform support.
      // Note that we lockTranslation on the final call to convert transform to relative positioning.
      if (Device.isMobile()) {
        this.resetTransforms(this.scale, deltaX, deltaY);
        this.repositionBackground();
      } else if (startingPosition != null) {
        this.recenter(
          startingPosition.left + deltaX,
          startingPosition.top + deltaY
        );
      }
      $(this).trigger("drag");
    };

    const endDrag = event => {
      if (Device.isMobile()) {
        this.lockTranslation();
      }
      startingPosition = null;
      this.dragWindow.css({
        left: "0px",
        top: "0px"
      });

      $(this).trigger("dragstop");
    };

    this.dragWindow.on("dragstart", event => {
      startingMousePosition = new Point(
        event.originalEvent.pageX,
        event.originalEvent.pageY
      );
      currentMousePosition = startingMousePosition;
      startingPosition = this.center.position();
    });

    this.dragWindow.on("drag", event => {
      currentMousePosition = new Point(
        event.originalEvent.pageX,
        event.originalEvent.pageY
      );

      // Note: dragWindow starts at 0,0 always.
      this.dragWindow.css({
        left: currentMousePosition.x - startingMousePosition.x,
        top: currentMousePosition.y - startingMousePosition.y
      });

      repositionDuringDrag();
    });

    const detector = new TouchDetector(document);
    $(detector).on("touchmove", (event, data, originalEvent) => {
      const left = parseInt(this.dragWindow.css("left"));
      const top = parseInt(this.dragWindow.css("top"));
      this.dragWindow.css({
        left: left + data.xdiff + "px",
        top: top + data.ydiff + "px"
      });

      repositionDuringDrag();

      // Prevent any page scrolling on the document. This usually occurs
      // when a modal is present, and the page instinctively wants to scroll.
      originalEvent.preventDefault();
    });

    this.dragWindow.on("dragstop", endDrag);
    $(detector).on("touchend", endDrag);
    $(detector).on("pinch", (event, data) => {
      const point = this.pointFromPageCoordinates(data.pageX, data.pageY);
      this.setScale(this.getScale() * data.scale, point);
    });

    UploadManager.watch(this);
  }

  recenter(x, y) {
    let point = null;
    if (x instanceof Point) {
      point = x;
    } else {
      point = new Point(x, y);
    }
    this.center.css({
      left: point.x + "px",
      top: point.y + "px"
    });

    this.repositionBackground();
    return $(this).trigger("pan");
  }

  move(xdiff, ydiff) {
    const position = this.center.position();
    return this.recenter(position.left + xdiff, position.top + ydiff);
  }

  // x and y are the delta the board should be translated
  // Note: Much of this moved to Util.
  resetTransforms(scale, x, y) {
    setTransform(this.center, scale || this.scale, x, y);

    // Hacky?
    return $(this).trigger("pan");
  }

  //
  //	 * Convert tranlations to relative positioning.
  //
  lockTranslation() {
    const centerPosition = this.getCompleteCenterPosition();
    this.resetTransforms();
    return this.recenter(centerPosition);
  }

  // Set the scale and optionally translate on a point in local coordinates.
  setScale(scale, point) {
    if (scale === this.scale) {
      return;
    }

    // Use the point as our
    point = point || this.getViewportCenter();

    // Everything's already in the local coordinate system. Let's get the positions
    // of the point at both scales and subtract the difference.
    const startingPosition = new Point(
      point.x * this.scale,
      point.y * this.scale
    );
    const newPosition = new Point(point.x * scale, point.y * scale);
    this.resetTransforms(scale);
    const scaledBackgroundWidth = parseInt(this.backgroundImageWidth * scale);
    const scaledBackgroundHeight = parseInt(this.backgroundImageHeight * scale);
    const newBackgroundString =
      scaledBackgroundWidth + "px " + scaledBackgroundHeight + "px";
    this.container.css({
      "-webkit-background-size": newBackgroundString,
      "-moz-background-size": newBackgroundString,
      "background-size": newBackgroundString
    });

    // repositionBackground() needs the new scale to be set before
    // being called; it's called eventually by calling move().
    this.scale = scale;
    this.move(
      startingPosition.x - newPosition.x,
      startingPosition.y - newPosition.y
    );

    // For symmetry with other code, specicially application / config code.
    // Change the scale in Config when scale changes. Listeners can listen
    // on the scale_change event too.
    Config.set("scale", this.scale);
    return $(this).trigger("zoom", [this.scale]);
  }

  //
  //	 * Reposition the background relative to the center point. Take into account
  //	 * both relative positioning of the center point as well as any matrix translation.
  //
  repositionBackground() {
    const centerPosition = this.getCompleteCenterPosition();

    // Adjust to match CSS "center center" background positioning, where
    // the center of the image is at the point specified, not the top left.
    // This is purely for visual effect when the viel is removed.
    centerPosition.translate(
      -(this.backgroundImageWidth / 2) * this.scale,
      -(this.backgroundImageHeight / 2) * this.scale
    );
    this.container.css({
      backgroundPosition: centerPosition.x + "px " + centerPosition.y + "px"
    });
  }

  getMatrixTranslation() {
    const matrixItems =
      (this.center.css("transform") || "").match(/(-?\d+\.?\d*)/g) || [];

    // Translation poritions of the matrix transform.
    const matrixX = parseInt(matrixItems[4] || 0);
    const matrixY = parseInt(matrixItems[5] || 0);
    return new Point(matrixX, matrixY);
  }

  getCompleteCenterPosition() {
    const matrixDiff = this.getMatrixTranslation();
    const left = parseInt(this.center.css("left"));
    const top = parseInt(this.center.css("top"));
    const positionX = left + matrixDiff.x;
    const positionY = top + matrixDiff.y;
    return new Point(positionX, positionY);
  }

  // Add an item to this axis, with a point relative to the center of the axis.
  addItem(item) {
    $(item.view).on("focussed mousedown", event => this.bringItemToFront(item));
    item.view.setCork(this);

    if (item.z == null) {
      item.z = this.getNextZIndex();
    }
    this.maxZIndex = Math.max(item.z, this.maxZIndex);

    // Monitor items for z changes. Helps with
    // changes made by RTC, and keeing the currently focussed
    // item on top.
    item.on("z_change", (event, z) => {
      this.maxZIndex = Math.max(item.z, this.maxZIndex);
      const focussed = FocusManager.getFocus();

      // Don't make anything higher than the item you're currently
      // working with. i.e., if something comes in via RTC, make ours
      // larger. This will trigger this same function, but in that case
      // this if statement won't resolve (MAKE SURE!... endless loop).
      if (focussed && focussed !== item && focussed.z < this.maxZIndex) {
        focussed.set("z", this.getNextZIndex());
      }
    });

    this.center.append(item.view.container);
    item.view.render();
  }

  removeItem(item) {
    item.view.container.remove();
  }

  addItemFromEvent(obj, event) {
    const point = this.pointFromPageCoordinates(event.pageX, event.pageY);
    const item = Item.create({
      x: point.x,
      y: point.y,
      z: this.getNextZIndex(),
      object: obj,
      objectType: obj.objectType
    });
    return item;
  }

  // Get a point relative to the center by passing in coordinates
  // relative to the window (i.e., [0,0] is the to left corner of the page).
  pointFromPageCoordinates(pageX, pageY) {
    const point = new Point(pageX, pageY);
    this.translate(point);
    point.x = point.x / this.scale;
    point.y = point.y / this.scale;
    return point;
  }

  // Get the point representing the viewport (screen) center relative to
  // the top left position of the board.
  getViewportCenter() {
    return this.pointFromPageCoordinates(this.width() / 2, this.height() / 2);
  }

  // Translate a point or rectangle relative to the top left of the container
  // to being relative to the center point.
  translate(thing) {
    const position = this.center.position();
    return thing.translate(-position.left, -position.top);
  }

  // Translate a point or rectangle relative to the center point to being relative
  // to the top left of the container. Must be either Point or Rectangle.
  reverseTranslate(thing) {
    const position = this.getCompleteCenterPosition();

    // Remember: Rectangle or Point.
    // TODO: Have Rectangle and Point extend Shape.
    return thing.translate(position.x, position.y);
  }

  getViewingRectangle() {
    const position = this.position();
    const topLeft = this.pointFromPageCoordinates(0, 0);
    const bottomRight = this.pointFromPageCoordinates(
      position.x + this.width(),
      position.y + this.height()
    );
    return new Rectangle(topLeft, bottomRight);
  }

  getMaxZIndex() {
    return this.maxZIndex;
  }

  getNextZIndex() {
    this.maxZIndex = this.maxZIndex + 1;
    return this.maxZIndex;
  }

  getBoard() {
    return this.container;
  }

  bringItemToFront(item) {
    if (item.z !== this.getMaxZIndex()) {
      return item.set("z", this.getNextZIndex());
    }
  }

  getScale() {
    return this.scale;
  }

  setCenter(point) {
    const newCenter = new Point(
      this.width() / 2 - point.x,
      this.height() / 2 - point.y
    );
    return this.recenter(newCenter);
  }

  // Zoom to an item by animating center position and scale.
  zoomTo(item) {
    const { width, height } = item.object;
    let finalScale = 1;

    // Compute the scale we'll be moving to.
    if (width > height) {
      finalScale = Math.min(
        1,
        (this.width() - 2 * this.minimumZoomPadding) / width
      );
    } else {
      finalScale = Math.min(
        1,
        (this.height() - 2 * this.minimumZoomPadding) / height
      );
    }

    // Compute the difference needed to keep center on point.
    const viewportCenter = this.getViewportCenter();

    // Start with the item's position in local coordinates.
    const newPosition = item.position();

    // Find the center of that item.
    newPosition.x = newPosition.x + width / 2;
    newPosition.y = newPosition.y + height / 2;
    const xdiff = viewportCenter.x - newPosition.x;
    const ydiff = viewportCenter.y - newPosition.y;

    // Get the starting scale, and the range with which we'll be animating.
    const startScale = this.getScale();
    const scaleRange = finalScale - startScale;

    // Just in case -- stop all animations currently on the center point.
    this.center.stop();
    let newScale = startScale;
    const duration = this.zoomAnimationDuration;
    const startTime = new Date().getTime();
    let currentTime = startTime;

    //var sqrtStartScale = Math.sqrt(startScale);
    //var sqrtFinalScale = Math.sqrt(finalScale);
    const takeNextStep = () => {
      const stepTime = new Date().getTime();
      let step = stepTime - currentTime;
      const time = stepTime - startTime;
      if (time > duration) {
        step -= time - duration;
      }

      // The ratio at which this step relates to the total duration.
      const stepScale = step / duration;

      //var timeDiff = time / duration;
      //var inSqrtSpace = ((timeDiff * sqrtFinalScale) + (1 - timeDiff) * sqrtStartScale);
      //var newScale = Math.pow(inSqrtSpace, 2);

      //var oldScale = this.getScale();
      newScale = this.getScale() + scaleRange * stepScale;
      const xstep = xdiff * stepScale * startScale;
      const ystep = ydiff * stepScale * startScale;
      this.setScale(newScale, newPosition);
      this.move(xstep, ystep);
      currentTime = stepTime;
      if (time < duration) {
        setTimeout(() => takeNextStep(), 0);
      }
    };

    // Drive Geeves.
    return takeNextStep();
  }
}

export { Cork };
