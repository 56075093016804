import { NoteApp } from "src/api/v1";

import { KeyCodes } from "src/base/KeyCodes";
import { StatusCodes } from "src/base/StatusCodes";

import { CTAButton } from "src/controls/buttons/CTAButton";

import { AccessControl } from "src/helpers/AccessControl";
import { NoticeManager } from "src/helpers/NoticeManager";

class LoginSubscriberModal {
  messageText;
  success;
  closeable;

  constructor({ messageText, success, closeable }) {
    this.messageText = messageText;
    this.success = success;
    this.closeable = closeable;
  }

  show() {
    NoticeManager.showModal({
      header: "Login",
      tabs: {
        Creds: this.createSubscriberLoginTab(),
        "Forgot?": this.createForgotPasswordTab()
      },

      className: "login",
      closeable: this.closeable
    });

    this.afterShow();
  }

  afterShow() {
    $(".notice input")
      .first()
      .focus();
  }

  createSubscriberLoginTab() {
    const dialog = $(document.createElement("div"));

    //dialog.addClass("login");
    const email = $(document.createElement("input"));
    email.addClass("email");
    email.attr("type", "text");
    email.attr("placeholder", "Email address");
    const password = $(document.createElement("input"));
    password.addClass("password");
    password.attr("type", "password");
    password.attr("placeholder", "Password");
    const loginText = "Sign in";
    const login = CTAButton.createSmall(loginText, "inline submit");
    const message = $(document.createElement("div"));
    message.addClass("message ei-pushpin");
    message.html(this.messageText || "");
    if (this.messageText == null) {
      message.hide();
    }
    const changeMessage = text => {
      message.html(text);
      if (message.is(":hidden")) {
        return message.animate({
          height: "toggle",
          opacity: "toggle"
        });
      } else {
        message.hide();
        return message.fadeIn();
      }
    };

    const sendRequest = () => {
      CTAButton.disable(login);
      CTAButton.changeText(login, "Please wait...");

      const failure = (jqXHR, textStatus, errorThrown) => {
        if (jqXHR.status === StatusCodes.RATE_LIMIT) {
          changeMessage("Please slow down.");
        } else {
          const data = JSON.parse(jqXHR.responseText);

          if (data && jqXHR.status === StatusCodes.UNAUTHORIZED) {
            changeMessage(data.error);
          } else {
            changeMessage("Server error. Please retry.");
          }
        }
        CTAButton.changeText(login, loginText);
        return CTAButton.enable(login);
      };

      return AccessControl.login(
        email.val(),
        password.val(),
        this.success,
        failure
      );
    };

    login.on("click", sendRequest);
    email.on("keypress", event => {
      if (event.which === KeyCodes.ENTER) {
        return sendRequest();
      }
    });

    password.on("keypress", event => {
      if (event.which === KeyCodes.ENTER) {
        return sendRequest();
      }
    });

    dialog.append(message);
    dialog.append(email);

    dialog.append(password);
    dialog.append("<br>");
    dialog.append(login);
    return dialog;
  }

  createForgotPasswordTab() {
    const dialog = $(document.createElement("div"));
    dialog.addClass("login");
    const email = $(document.createElement("input"));
    email.addClass("email");
    email.attr("type", "text");
    email.attr("placeholder", "Email address");
    const stepOneButtonText = "Send me an email!";
    const defaultMessageText = "Forgot? We can help.";
    const send = CTAButton.createSmall(stepOneButtonText, "inline submit");
    const message = $(document.createElement("div"));
    message.addClass("message ei-pushpin");
    message.html(defaultMessageText);
    const changeMessage = text => {
      message.html(text);
      message.hide();
      return message.fadeIn();
    };

    const failure = (jqXHR, textStatus, errorThrown) => {
      const data = JSON.parse(jqXHR.responseText);

      if (data && jqXHR.status === StatusCodes.UNPROCESSABLE_ENTITY) {
        const key = Object.keys(data.errors)[0] || "";
        const message = `${key} ${data.errors[key]}`;
        changeMessage(message[0].toUpperCase() + message.slice(1));
      } else {
        changeMessage("Server error.");
      }
      CTAButton.changeText(send, "Try again");
      return CTAButton.enable(send);
    };

    const sendForgotPasswordRequest = () => {
      const emailValue = email.val();
      if (emailValue == null) {
        changeMessage("Please enter an email.");
        return;
      }
      const success = () => {
        changeMessage("Email sent! See instructions.");
        email.hide();
        return send.slideUp();
      };

      CTAButton.disable(send);
      CTAButton.changeText(send, "Please wait...");

      NoteApp.API.User.send_reset_password_instructions({
        email: emailValue,
        success,
        failure
      });
    };

    // Handlers to make usability easier.
    send.on("click", sendForgotPasswordRequest);
    email.on("keypress", event => {
      if (event.which === KeyCodes.ENTER) {
        return sendForgotPasswordRequest();
      }
    });

    dialog.append(message);
    dialog.append(email);
    dialog.append(send);
    return dialog;
  }
}

export { LoginSubscriberModal }
