import React from "react";

import { Config } from "src/config";
import { AccessControl } from "src/helpers/AccessControl";

import { Chat } from "src/controls/buttons/Chat";
import { ChatStatus } from "src/controls/buttons/ChatStatus";
import { GenericTextButton } from "src/controls/buttons/GenericTextButton";
import { Help } from "src/controls/buttons/Help";
import { Map } from "src/controls/buttons/Map";
import { NoteColor } from "src/controls/buttons/NoteColor";
import { PoweredBy } from "src/controls/buttons/PoweredBy";
import { Settings } from "src/controls/buttons/Settings";

class BottomToolbar extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      open: {
        chat: false,
        help: false,
        color: false,
        poweredby: false,
        settings: false,
        map: false
      }
    };
  }

  // Close all other buttons except for the one just clicked.
  closeOthers(target) {
    const { open } = this.state;

    if (open[target]) {
      open[target] = false;
    } else {
      this.props.onPopupOpen();
      Object.keys(open).forEach(key => (open[key] = key === target));
    }

    this.setState({ ...this.state, open });
  }

  getButtons() {
    const buttons = {
      chat: (
        <Chat
          key="chat"
          onClick={() => this.closeOthers("chat")}
          visible={this.state.open["chat"]}
          rtc={this.props.rtc}
        />
      ),
      chatStatus: (
        <ChatStatus
          key="chatstatus"
          onClick={() => this.closeOthers("chatstatus")}
          visible={this.state.open["chatstatus"]}
          rtc={this.props.rtc}
        />
      ),
      help: (
        <Help
          key="help"
          onClick={() => this.closeOthers("help")}
          visible={this.state.open["help"]}
        />
      ),
      noteColor: (
        <NoteColor
          key="color"
          onClick={() => this.closeOthers("color")}
          visible={this.state.open["color"]}
          onChange={this.props.onColorChange}
        />
      ),
      poweredBy: <PoweredBy key="poweredby" />,
      settings: (
        <Settings
          key="settings"
          onClick={() => this.closeOthers("settings")}
          visible={this.state.open["settings"]}
          onLogin={this.props.onLogin}
          onLogout={this.props.onLogout}
        />
      ),
      map: (
        <Map
          key="map"
          onClick={() => this.closeOthers("map")}
          visible={this.state.open["map"]}
          cork={this.props.cork}
        />
      ),
      dashboard: (
        <GenericTextButton
          key="dashboard"
          onClick={() => (window.location.href = "/dashboard")}
        >
          &larr; Back to Dashboard
        </GenericTextButton>
      )
    };

    return buttons;
  }

  buildToolbar() {
    const buttons = this.getButtons();

    const leftButtons = [];
    const rightButtons = [];

    if (Config.isViewOnlyURL() || Config.isEmbed()) {
      leftButtons.push(buttons.poweredBy);

      if (AccessControl.postAndEditAllowed()) {
        rightButtons.push(buttons.noteColor);
      }

      rightButtons.push(buttons.map);
    } else {
      if (AccessControl.chatAllowed()) {
        leftButtons.push(buttons.chat);
        leftButtons.push(buttons.chatStatus);
      }

      if (AccessControl.isLoggedIn()) {
        rightButtons.push(buttons.dashboard);
      }
      rightButtons.push(buttons.settings);

      if (AccessControl.postAndEditAllowed()) {
        rightButtons.push(buttons.noteColor);
      }

      rightButtons.push(buttons.map);
      rightButtons.push(buttons.help);
    }

    return {
      leftButtons,
      rightButtons
    };
  }

  render() {
    const { leftButtons, rightButtons } = this.buildToolbar();

    return (
      <div className="bar no-user-select">
        <div className="background-rgba-fix"></div>
        <div className="left">{leftButtons}</div>
        <div className="right">{rightButtons}</div>
      </div>
    );
  }
}

export { BottomToolbar };
