const StatusCodes = {
  // Please add as needed.
  SUCCESS: 200,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  UNPROCESSABLE_ENTITY: 422,
  RATE_LIMIT: 429,
  INTERNAL_SERVER_ERROR: 500
};

export { StatusCodes };
