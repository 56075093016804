import "../../stylesheets/noteapp.sass";

import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
Sentry.init({
  dsn: window.sentry_dsn,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.05,
});

import "init/noteapp";

import { Application } from "src/Application";

import Rails from "@rails/ujs";
Rails.start();

new Application();
