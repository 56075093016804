import { Config } from "src/config";
import { YesNoModal } from "./YesNoModal";

class InvalidEmbedModal extends YesNoModal {
  constructor() {
    super();
    this.pricingURL = `${Config.get("host")}/pricing`;
    this.options = {
      header: "Upgrade now!",
      text:
        "Upgrade to take advantage of third-party site embeds,<br>view-only URLs and much more.",
      className: "white",

      yesfn: () => this.learnMore(),
      nofn: () => this.viewBoard(),

      closeable: false,
      hideAfter: false,
      yesText: "See plans and pricing",
      noText: "View board"
    };

    if (Config.isViewOnlyURL()) {
      this.options.className = "white onlyyes";
    }
  }

  learnMore() {
    window.open(this.pricingURL);
  }

  viewBoard() {
    window.open(`${Config.get("host")}/${Config.getIdentifier()}`);
  }
}

export { InvalidEmbedModal };
