import React from "react";
import Select from "react-select";

import { Config } from "src/config";

import { UserIcon } from "src/controls/UserIcon";

import { Popup } from "src/controls/chrome/Popup";

import { CorkSettingsModal } from "src/controls/modals/CorkSettingsModal";
import { UserSettingsModal } from "src/controls/modals/UserSettingsModal";

import { AccessControl } from "src/helpers/AccessControl";

class Settings extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.buttonRef = React.createRef();

    const user = Config.get("user");
    user.on("image_url_change", () => {
      this.setState({ ...this.state, user });
    });

    this.state = {
      buttonWidth: null,
      popupVisible: false,
      hoveringPopup: false,
      user
    };
  }

  componentDidMount() {
    this.setState({ buttonWidth: this.buttonRef.current.offsetWidth });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible != this.props.visible) {
      if (!this.props.visible) {
        this.close();
      } else {
        this.show();
      }
    }
  }

  show() {
    this.setState({ popupVisible: true });
  }

  close() {
    this.setState({ popupVisible: false, hoveringPopup: false });
  }

  handlePopupHover(hoveringPopup) {
    this.setState({ hoveringPopup });
  }

  getPopup() {
    let boards,
      boardSeparator,
      dashboard,
      userSettings,
      boardSettings,
      login,
      logout;

    // Show any boards if the user has 'em -- note, empty if guest.
    if (this.state.user.accounts.length > 0) {
      const options = [];

      for (let account of this.state.user.accounts) {
        const optgroup = { label: account.group_name, options: [] };
        let index = 0;

        while (index < account.boards.length) {
          const string_identifier = account.boards[index].string_identifier;
          optgroup.options.push({
            label: `/${string_identifier}`,
            value: string_identifier,
            isDisabled: string_identifier === Config.get("string_identifier")
          });
          index++;
        }

        options.push(optgroup);
      }

      const selectStyles = {
        container: base => ({
          ...base,
          fontSize: 12,
          color: "black",
          textShadow: "none"
        }),
        control: base => ({
          ...base,
          height: 28,
          minHeight: 28
        }),
        indicatorsContainer: base => ({
          ...base,
          height: 28,
          minHeight: 28
        }),
        groupHeading: base => ({
          ...base,
          fontSize: 12,
          color: "black",
          fontWeight: "bold",
          textTransform: "none"
        }),
        option: base => ({
          ...base,
          paddingLeft: 22
        })
      };

      const handleBoardChange = option => {
        const string_identifier = option.value;
        if (string_identifier !== Config.get("string_identifier")) {
          document.location.href = "/" + string_identifier;
        }
      };

      boards = (
        <li className="board-select">
          <div className="wrapper" onClick={event => event.stopPropagation()}>
            <Select
              styles={selectStyles}
              options={options}
              placeholder="Jump to..."
              isSearchable={false}
              menuPlacement="auto"
              onChange={handleBoardChange}
            />
          </div>
        </li>
      );

      boardSeparator = (
        <li id="sep-1" className="separator">
          <div className="wrapper"></div>
        </li>
      );
    }

    // Decide whether or not to show Account Settings options.
    if (AccessControl.isLoggedIn()) {
      dashboard = (
        <li
          className="manage-team clickable"
          onClick={() => (window.location.href = "/dashboard")}
        >
          <div className="wrapper">
            <div className="image-div ei-popup-team">Dashboard</div>
          </div>
        </li>
      );

      const showUsm = () => {
        const usm = new UserSettingsModal();
        usm.show("User Settings");
      };

      userSettings = (
        <li className="manage-team clickable" onClick={showUsm}>
          <div className="wrapper">
            <div className="image-div ei-popup-gear">User Settings</div>
          </div>
        </li>
      );
    }

    // Decide whether or not to show Board Settings options.
    if (AccessControl.isAccountAdmin()) {
      const showCsm = () => {
        const csm = new CorkSettingsModal(Config.get("board"));
        csm.show();
      };

      boardSettings = (
        <li className="board-settings clickable" onClick={showCsm}>
          <div className="wrapper">
            <div className="image-div ei-popup-edit">Board Settings</div>
          </div>
        </li>
      );
    }

    // Decide whether or not to show Login/Logout
    if (AccessControl.isLoggedIn()) {
      logout = (
        <li className="logout clickable" onClick={this.props.onLogout}>
          <div className="wrapper">
            <div className="image-div">Logout</div>
          </div>
        </li>
      );
    } else {
      login = (
        <li
          className="login clickable"
          onClick={() => this.props.onLogin(this.show.bind(this))}
        >
          <div className="wrapper">
            <div className="image-div ei-popup-gear">Collaborator Login</div>
          </div>
        </li>
      );

      // Decide wehther or not to show Leave Board.
      if (Config.get("board").isPasswordProtected()) {
        logout = (
          <li className="logout clickable" onClick={this.props.onLogout}>
            <div className="wrapper">
              <div className="image-div">Logout</div>
            </div>
          </li>
        );
      }
    }

    return (
      <Popup
        buttonWidth={this.state.buttonWidth}
        type="settings"
        visible={this.state.popupVisible}
        onMouseEnter={() => this.handlePopupHover(true)}
        onMouseLeave={() => this.handlePopupHover(false)}
      >
        <ul className="options">
          <li className="user">
            <div className="wrapper">{this.state.user.displayName()}</div>
          </li>
          {boards}
          {boardSeparator}
          {dashboard}
          {userSettings}
          {boardSettings}
          {login}
          {logout}
        </ul>
      </Popup>
    );
  }

  render() {
    const noHover = this.state.hoveringPopup ? "nohover" : "";

    return (
      <div
        ref={this.buttonRef}
        onClick={this.props.onClick}
        className={`button settings ${noHover}`}
        title="User Settings"
      >
        {this.getPopup()}
        <div className={`image`}>
          <UserIcon user={this.state.user} title="User Settings" />
        </div>
      </div>
    );
  }
}

export { Settings };
